/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS267D
 * 화면 설명: 병력미결 상품정보 및 신청서확인
 */
<template> 
  <!-- Content영역 -->
  <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">
    <ur-box-container alignV="start" componentid="" direction="column" class="pb50">
      <template v-if="pItem.contStatCdNm === '작성대상'">
        <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="pal1624">
          <span class="fs22rem fwb">{{pItem.contStatCdNm}}</span>
          <div><span class="fs20rem crTy-blue2 fwb mr2">1</span>/<span class="ml2">2</span></div>
        </ur-box-container>
        <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="bd-T-Ty1 h10px bgcolor-3"></ur-box-container>
        <ur-box-container direction="column" alignV="start" class="pa2024 bd-b-Ty1"> 
          <ul class="bullet-type-3 crTy-bk7 fs14rem">
            <li><span class="crTy-orange2">[미리보기]</span>를 통해 미결보완 필요내용이 맞는지 <span class="crTy-orange2">반드시 확인 후</span> 발송 부탁드립니다.</li>
          </ul>
        </ur-box-container>
      </template>
      <ur-box-container direction="column" alignV="start" class="pa2024"> 
        <div class="crTy-bk7 fs14rem">상품명</div>
        <div class="mt10">
          <b class="txtSkip fs19rem">{{pItem.prdtNm}}</b>            
        </div>
      </ur-box-container>        
      <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="bd-T-Ty1 h10px bgcolor-3"></ur-box-container>

      <ur-box-container direction="column" alignV="start" class="pa2024 pb10 bd-b-Ty1"> 
        <div class="crTy-bk7 fs14rem">신청서</div>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list pa1624-list">
        <mo-list-item>
          <mo-checkbox v-model="checkbox1" :disabled="pItem.contStatCdNm === '작성중'"></mo-checkbox>
          <div class="list-item__contents">
            <div class="list-item__contents__title fexTy5 mb0">
              <span class="name fwn fs16rem fexTy5 btn-flex__full__wrap">
                <span class="flex__full w150px txtSkip">추가병력고지서</span>
                <span class="crTy-blue4 pr10 pl10" v-if="pItem && pItem.pSAnmsObj && pItem.pSAnmsObj.mobpsUrlDspchYn === 'Y'  && (pItem.pSAnmsObj.contrElstYn !== 'Y' || pItem.pSAnmsObj.insrdElstYn !== 'Y')">작성중</span>
                <span class="crTy-blue4 pr10 pl10" v-else-if="pItem && pItem.pSAnmsObj && pItem.pSAnmsObj.mobpsUrlDspchYn === 'Y'  && (pItem.pSAnmsObj.contrElstYn === 'Y' && pItem.pSAnmsObj.insrdElstYn === 'Y')">작성완료</span>
                <mo-button class="ns-btn-round h28 blue-line fs14rem" @click="fn_ClickPreview('S1')">미리보기</mo-button>
              </span>
            </div>
            <div class="list-item__contents__info pal0">
              <span class="crTy-bk2">{{$bizUtil.dateDashFormat(pItem.pSAnmsObj.erdat)}} {{fn_Time(pItem.pSAnmsObj.erzet)}}</span>
            </div>
          </div>  
        </mo-list-item>
      </ur-box-container> 
    </ur-box-container>
    <!-- Content 영역 end -->
    <template>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div" v-if="pItem.contStatCdNm !== '작성대상'">
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="$emit('closePop')">확인</mo-button>
        </div>
        <div class="relative-div" v-if="pItem.contStatCdNm === '작성대상'">
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round white" @click="$emit('closePop')">취소</mo-button>
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Confirm()" :disabled="!canGoNext">다음</mo-button>
        </div>
      </ur-box-container>
    </template>
  </ur-box-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 

export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS267D', 
  screenId: 'MSPPS267D',

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    pItem: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {            
      checkbox1: false,
    }
  },//data

 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },//mounted

 /***********************************************************************************
  * Computed 함수 정의 영역
  ***********************************************************************************/
  computed: {
    canGoNext () {
      // 체크박스 선택 시에 넘어갈 수 있음
      let ret = false

      if ( this.pItem.contStatCdNm !== '작성중' && this.checkbox1 ) return true
      return ret
    },
  },//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명  : fn_ClickPreview
     * 설명        : 사랑On에서 WebView로 호출
     ******************************************************************************/
    fn_ClickPreview: function (serviceName) {
      this.fn_ServiceData(serviceName)
    },

    /******************************************************************************
     * Function명  : fn_OpenWebViewURL
     * 설명        : 사랑On에서 WebView로 호출
     ******************************************************************************/
    fn_OpenWebViewURL: function (pURL) {
      let serverChoice = process.env.NODE_ENV
      let targetUrl = pURL

      if (this.$commonUtil.isMobile()) {
        window.fdpbridge.exec('PhoneWebViewPlugin', {uri: targetUrl}, () => {}, () => {})
      } else {
        // 운영환경이 아닌 경우 get 방식으로 파라미터 호출
        if (serverChoice !== 'production') {
          window.open(targetUrl, '_blank')
        }
      }
    },
    /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler() {
      return
    }, //fn_DevicBackBtnHandler

    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : 샘플 서비스 호출 함수
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      this.$router.go(-1)      
    },// fn_HeaderBackBtnHandler

    /******************************************************************************
    * Function명  : fn_ServiceData
    * 설명        : 서비스 호출 함수 정의
    ******************************************************************************/
    fn_ServiceData: function (serviceName) {
      let pParams = {}
      //this.isLoadingStatus = true
      let tParams = {}
      switch (serviceName) {
        case 'S1': // 암호화된 URL
          tParams.appId = 'tss.ps'
          tParams.trnstId = 'txTSSPS90S1'
          tParams.fnctScCd = 'S'
          tParams.serviceId = ''
          tParams.reqSrvcNm = 'IPSSpcChgSVCImpl'
          tParams.reqMthdNm = 'selURLData'
          tParams.reqVONm = 'sli.tss.ps.eltrnsign.svc.svo.PSSpcChgSVO'
          tParams.resVONm = 'sli.tss.ps.eltrnsign.svc.svo.PSSpcChgSVO'
          tParams.srnId = '' // 대표 화면 명
          tParams.data = {}
          tParams.data.vuchId = '' // 전자문서ID

          pParams = Object.assign({}, tParams)
          pParams.data = {}
          // 빈값검증 추가.
          pParams.data.vuchId = this.pItem.vuchId // 영수증ID
          pParams.data.contPtcpScCd = '1' // 미리보기 띄우는 메서드에서 사용하지 않는 필드
          pParams.data.spcCndtlElstSno = this.pItem.spcCndtlElstSno
          pParams.data.mobslElstScCd = this.pItem.mobslElstScCd
          break
        default:
          break
      } // end switch
            
      pParams.srnId = this.$options.screenId // 호출 화면 명
      PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)
    },

   /******************************************************************************
     * Function명  : fn_ServiceResultHandler
     * 설명        : 서비스 호출 결과 함수 정의
     ******************************************************************************/
    fn_ServiceResultHandler(event, serviceName) {
      let lv_data = event.data

      switch (serviceName) {
        case 'S1': // 모바일청약 중단건진행현황조회
          this.fn_OpenWebViewURL(lv_data.urlData)
          break
        default:
          break
      } // switch (serviceName)
    },

    /******************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 에러 이벤트
     ******************************************************************************/
    fn_ServiceFaultHandler: function (event, serviceName) {
      this.getStore('confirm').dispatch('ADD', event.msgDesc)
    },

    /******************************************************************************
    * Function명  : fn_Time
    * 설명        : 시간 포멧
    ******************************************************************************/
    fn_Time (str) {
      if (str) {
        return str.slice(0, 2) + ':' + str.slice(2, 4) + ':' + str.slice(4, 6)
      }
    },

    /******************************************************************************
     * Function명 : fn_Confirm
     * 설명       : Confirm 팝업 호출 - FC 주의 문구 노출
     ******************************************************************************/
    fn_Confirm () {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title: '알림',
          content: '<span class="crTy-orange2">[미리보기]</span>를 통해 미결보완 필요내용이 맞는지 <span class="crTy-orange2">반드시 확인 후</span> 발송 부탁드립니다.',
          title_pos_btn: "확인",
          title_neg_btn: "취소"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(this.lv_AlertPop);
            this.fn_Next()
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.lv_AlertPop);
          }
        }
      })
    },

    /******************************************************************************
     * Function명  : fn_Next
     * 설명        : 미결보완 발송화면으로 이동
     ******************************************************************************/
    fn_Next () {
      if (this.checkbox1) {
        this.$emit('nextBtnClicked')
      }
    },
    
    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>