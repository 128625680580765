/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS265P
 * 화면 설명: 미결보완 진행상태
 */
 <template>
  <ur-page-container class="msp" title="미결보완 진행상태" :show-title="true" type="subpage">
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">
      <ur-box-container v-if="loadComplete" alignV="start" componentid="" direction="column" class="pb82">
        <!-- 피보험자 -->
        <template v-if="!(JSON.stringify(lv_data['2']) === '{}')">
          <msp-expand :expanded="lv_insrdElstYn !== 'Y'" btn-area-first title-first expand-only-btn class="mo-list-expand agree-expand type-1" btn-area-class="fexTy3-1 firstdiv-full"><!-- //expanded 종료되지 않은 첫번째 계약관계자 펼침 -->
            <template #title>
              <div class="fexTy5">
                <span class="fs14rem crTy-bk7 min70">피보험자</span>
                <span class="fs19rem fwb txtSkip">{{lv_data['2'].mobslChnlCustNm}}</span>
                <mo-badge v-if="lv_insrdElstYn === 'Y'" class="badge-sample-badge sm min40 ml10 lightgray2" shape="status">종료</mo-badge>
              </div>
              <div class="full fexTy2 mt10">
                <!-- button 완료건 or 청약문서 확인 중인 상태 비활성 처리 -->
                <mo-button class="ns-btn-round h28 blue-fill fs14rem mr10" @click="fn_Send('2')" :disabled="lv_insrdElstYn === 'Y'">알림톡발송</mo-button>
                <mo-button class="ns-btn-round h28 blue-line fs14rem mr10" @click="fn_Send('2', '2')" :disabled="lv_insrdElstYn === 'Y'">바로열기</mo-button>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="expand-area">
                <div class="ing-content">
                  <div class="ing-item completed">
                    <span class="list-step fs14rem">서명전</span>
                  </div>
                  <div class="ing-item" :class="[{'completed' : lv_insrdElstYn === 'Y'}]">
                    <span class="list-step fs14rem">완료</span>
                  </div>
                </div>
              </div>
            </template>
          </msp-expand>
        </template>
        <!-- 피보험자 end -->

        <!-- 계약자 -->
        <template v-if="!(JSON.stringify(lv_data['1']) === '{}')">
          <msp-expand :expanded="(lv_contrInsrdSameYn === 'Y' && lv_contrElstYn !== 'Y') || (lv_insrdElstYn === 'Y' && lv_contrElstYn !== 'Y')" btn-area-first title-first expand-only-btn class="mo-list-expand agree-expand type-1" btn-area-class="fexTy3-1 firstdiv-full"><!-- // expanded 종료되지 않은 첫번째 계약관계자 펼침 -->
            <template #title>
              <div class="fexTy5">
                <span class="fs14rem crTy-bk7 min70">계약자</span>
                <span class="fs19rem fwb txtSkip">{{lv_data['1'].mobslChnlCustNm}}</span>
                <mo-badge v-if="lv_contrElstYn === 'Y'" class="badge-sample-badge sm min40 ml10 lightgray2" shape="status">종료</mo-badge>
              </div>
              <div class="full fexTy2 mt10">
                <!-- 피보험자 미완료 시 계약자 진행 불가 -->
                <mo-button class="ns-btn-round h28 blue-fill fs14rem mr10" @click="fn_Send('1')" :disabled="lv_contrInsrdSameYn === 'N'? lv_insrdElstYn === 'N' || lv_contrElstYn === 'Y': lv_contrElstYn === 'Y'">알림톡발송</mo-button>
                <mo-button class="ns-btn-round h28 blue-line fs14rem mr10" @click="fn_Send('1', '2')" :disabled="lv_contrInsrdSameYn === 'N'? lv_insrdElstYn === 'N' || lv_contrElstYn === 'Y': lv_contrElstYn === 'Y'">바로열기</mo-button>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="expand-area">
                <div class="ing-content">
                  <div class="ing-item completed">
                    <span class="list-step fs14rem">서명전</span>
                  </div>
                  <div class="ing-item" :class="[{'completed' : lv_contrElstYn === 'Y'}]">
                    <span class="list-step fs14rem">완료</span>
                  </div>
                </div>  
              </div>
            </template>
          </msp-expand>
        </template>
        <!-- 계약자 end-->
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Close">확인</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>

    </ur-box-container>

  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS265P', 
  screenId: 'MSPPS265P', 
  components: {
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    pItem: Object
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  //팝업 형태 속성
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      parntSrnId: null, // 부모 화면 ID
      showPopup: true,
      
      lv_data: {
        '1': {},
        '2': {},
        '3': {},
        '4': {},
        '5': {}
      },
      lv_contrElstYn: 'N',  //계약자전자서명여부
      lv_insrdElstYn: 'N',  //피보험자전자서명여부
      lv_contrInsrdSameYn: 'N', //계피동일여부
      pSAnmsObj: {},
      loadComplete: false
    }
  },//data

 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/
  created () {
    this.fn_OnLoad()
  },//created

  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },//mounted

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_OnLoad
    * 설명       : 초기화 함수
    ******************************************************************************/
    fn_OnLoad () {
      if (!this.pItem.vuchId) return
      let lv_Vm = this
      let pParams = {
        vuchId: this.pItem.vuchId
      }
      const trnstId = 'txTSSPS90S5'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          let lst = response.body.pSSpcChgPtcpInfoSVOList
          for (let i in lst) {
            let ptcpInfoSVO = lst[i]
            lv_Vm.lv_data[String(ptcpInfoSVO.contPtcpScCd)] = ptcpInfoSVO
          }
          lv_Vm.fn_SetProgress()
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_SetProgress
    * 설명       : 진행상태 초기화 함수
    ******************************************************************************/
    fn_SetProgress () {
      // 계약자 피보험자 청약완료여부
      this.lv_contrElstYn = this.pItem.pSAnmsObj.contrElstYn
      this.lv_insrdElstYn = this.pItem.pSAnmsObj.insrdElstYn
      // 계피동일여부
      this.lv_contrInsrdSameYn = this.pItem.pSAnmsObj.contrInsrdSameYn
      // 화면 로드 여부
      this.loadComplete = true
    },

    /******************************************************************************
    * Function명 : fn_Send
    * 설명       : 알림톡발송 또는 바로열기버튼 클릭시
    ******************************************************************************/
    fn_Send (pContPtcpScCd = '1', pSendKaKao) {
      var lv_Vm = this
      let isSendKaKao = true
      if (pSendKaKao && pSendKaKao === '2') isSendKaKao = false
      var trnstId = 'txTSSPS90P4'
      let pSAddmAnmsListSVO = []
      let pData = {}

      if (this.pItem.contStatCdNm === '작성중') {
        pData = this.pItem.pSAnmsObj
      }
      if (!_.isEmpty(pData)) {
        let pParam = {
          vuchId: lv_Vm.pItem.vuchId, // 영수증번호
          contPtcpScCd: pContPtcpScCd, // 계약관계인 구분코드
          spcCndtlElstSno: lv_Vm.pItem.pSAnmsObj.spcCndtlElstSno,
          mobslElstScCd: 'Y',
          elstPlanId: lv_Vm.pItem.vuchId,
          ContNo: '',
          isSendKaKao: isSendKaKao ? '1' : '2',
        }
        pSAddmAnmsListSVO.push(pParam)
      }

      let pParams = {
        listVO: pSAddmAnmsListSVO,
        contrInsrdSameYn: this.lv_contrInsrdSameYn
      }
      lv_Vm.post(lv_Vm, pParams, trnstId, 'S').then(function (response) {
        if (response.body && response.msgComm) {
          if (response.msgComm.msgCd === 'EFWS003') {
            if (response.body.listVO) {
              if (response.body.listVO[0] && response.body.listVO[0].isSendKaKao === '2') {
                lv_Vm.fn_OpenWebViewURL(response.body.listVO[0].urlData)
              } else {
                lv_Vm.getStore('confirm').dispatch('ADD', '알림톡이 발송되었습니다.')
                lv_Vm.fn_Close()
              }
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']<br />' + response.msgComm.msgDesc)
          }
        } else {
          if (response && response.msgComm && response.msgComm.msgCd) {
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']<br />' + response.msgComm.msgDesc)
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '알림톡 발송에 실패하였습니다.')
          }
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },

    /******************************************************************************
     * Function명  : fn_OpenWebViewURL
     * 설명        : 사랑On에서 WebView로 호출
     ******************************************************************************/
    fn_OpenWebViewURL: function (pURL) {
      let serverChoice = process.env.NODE_ENV
      let targetUrl = pURL

      if (this.$commonUtil.isMobile()) {
        window.fdpbridge.exec('PhoneWebViewPlugin', {uri: targetUrl}, () => {}, () => {})
      } else {
        // 운영환경이 아닌 경우 get 방식으로 파라미터 호출
        if (serverChoice !== 'production') {
          window.open(targetUrl, '_blank')
        }
      }
    },

    /******************************************************************************
    * Function명 : fn_Close
    * 설명       : 종료 함수
    *            :
    ******************************************************************************/
    fn_Close: function () {
      this.$emit('closePopup')
    },

    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>