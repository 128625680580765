/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS264P
 * 화면 설명: 병력미결 작성대상 및 미리보기
 */
 <template>
  <ur-page-container class="msp" :title="'미결보완 ' + pItem.contStatCdNm" :show-title="true" type="subpage" @on-header-left-click="fn_HeaderBackBtnHandler">
    <!-- <ur-box-container direction="column" alignV="start" class="msp-ps-wrap"> -->
      
      <!--정보기억 show-->
      <MSPPS267D v-show="phase === 1" @closePop="fn_Close" @nextBtnClicked="fn_Next" :pItem="pItem"></MSPPS267D>
      <!--정보리셋 if-->
      <MSPPS268D v-if="phase === 2" @closePop="fn_Close" @prevBtnClicked="fn_Prev" :pItem="pItem"></MSPPS268D>
      <!-- slot 끝 -->
      
      <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
      <div class="ns-height150"></div>

    <!-- </ur-box-container> -->

  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import MSPPS267D from '@/ui/ps/MSPPS267D'
import MSPPS268D from '@/ui/ps/MSPPS268D'

export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS264P', 
  screenId: 'MSPPS264P', 
  components: {
    MSPPS267D,
    MSPPS268D
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    pItem: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      parntSrnId: null, // 부모 화면 ID
      showPopup: true,
      phase: 1 // 1-> 미결보완/고객진행상태 탭 // 2-> 진행상태조회
    }
  },//data

 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },//mounted

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : 헤더 이전버튼 Handler
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      if (this.phase === 1) { //미결보완 상품정보 및 신청서 확인
        this.$emit('closePopup')
      }
      if (this.phase === 2) { //미결보완 계약 관계자 정보 확인
        this.phase = 1
      }
    },// fn_HeaderBackBtnHandler

    /******************************************************************************
    * Function명  : fn_Close
    * 설명        : 닫기버튼 Handler
    ******************************************************************************/
    fn_Close () {
      this.$emit('closePopup')
    },

    /******************************************************************************
    * Function명  : fn_Next
    * 설명        : 다음버튼 Handler
    ******************************************************************************/
    fn_Next () {
      this.phase = 2
    },
    fn_Prev () {
      this.phase = 1
    },

    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>