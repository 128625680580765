/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS263P
 * 화면 설명: 미결보완 고객 진행상태
 */
 <template>
  <ur-page-container class="msp" title="미결보완 고객 진행상태" :show-title="true" type="subpage">
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">
      <ur-box-container v-if="loadComplete" alignV="start" componentid="" direction="column" class="pb82">
        <!-- 피보험자 -->
        <template v-if="!(JSON.stringify(lv_data['2']) === '{}')">
          <msp-expand :expanded="lv_insrdElstYn !== 'Y'" btn-area-first title-first expand-only-btn class="mo-list-expand agree-expand type-1" btn-area-class="fexTy3-1"><!-- //expanded 종료되지 않은 첫번째 계약관계자 펼침 -->
            <template #title>
              <div class="fexTy5">
                <span class="fs14rem crTy-bk7 min70">피보험자</span>
                <span class="fs19rem fwb txtSkip">{{lv_data['2'].mobslChnlCustNm}}</span>
                <mo-badge v-if="lv_insrdElstYn === 'Y'" class="badge-sample-badge sm min40 ml10 lightgray2" shape="status">종료</mo-badge>
              </div>
              <div class="full fexTy2 mt10">
                <!-- button 완료건 or 청약문서 확인 중인 상태 비활성 처리 -->
                <mo-button class="ns-btn-round h28 blue-fill fs14rem mr10" @click="fn_Send('2')" :disabled="lv_insrdElstYn === 'Y'">알림톡발송</mo-button>
                <mo-button class="ns-btn-round h28 blue-line fs14rem mr10" @click="fn_Send('2', '2')" :disabled="lv_insrdElstYn === 'Y'">바로열기</mo-button>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="expand-area">
                <div class="ing-content">
                  <div class="ing-item completed">
                    <span class="list-step fs14rem">서명전</span>
                  </div>
                  <div class="ing-item" :class="[{'completed' : lv_insrdElstYn === 'Y'}]">
                    <span class="list-step fs14rem">완료</span>
                  </div>
                </div>
              </div>
            </template>
          </msp-expand>
        </template>
        <!-- 피보험자 end -->

        <!-- 계약자 -->
        <template v-if="!(JSON.stringify(lv_data['1']) === '{}')">
          <msp-expand :expanded="lv_insrdElstYn === 'Y' && lv_contrElstYn !== 'Y'" btn-area-first title-first expand-only-btn class="mo-list-expand agree-expand type-1" btn-area-class="fexTy3-1"><!-- // expanded 종료되지 않은 첫번째 계약관계자 펼침 -->
            <template #title>
              <div class="fexTy5">
                <span class="fs14rem crTy-bk7 min70">계약자</span>
                <span class="fs19rem fwb txtSkip">{{lv_data['1'].mobslChnlCustNm}}</span>
                <mo-badge v-if="lv_contrElstYn === 'Y'" class="badge-sample-badge sm min40 ml10 lightgray2" shape="status">종료</mo-badge>
              </div>
              <div class="full fexTy2 mt10">
                <mo-button class="ns-btn-round h28 blue-fill fs14rem mr10" @click="fn_Send('1')" :disabled="lv_contrElstYn === 'Y'">알림톡발송</mo-button>
                <mo-button class="ns-btn-round h28 blue-line fs14rem mr10" @click="fn_Send('1', '2')" :disabled="lv_contrElstYn === 'Y'">바로열기</mo-button>
              </div>
            </template>
            <template #btn>
                <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <div class="expand-area">
                <div class="ing-content">
                  <div class="ing-item completed">
                    <span class="list-step fs14rem">서명전</span>
                  </div>
                  <div class="ing-item" :class="[{'completed' : lv_contrElstYn === 'Y'}]">
                    <span class="list-step fs14rem">완료</span>
                  </div>
                </div>  
              </div>
            </template>
          </msp-expand>
        </template>
        <!-- 계약자 end-->
      </ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Close">확인</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>

    </ur-box-container>

  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import PSServiceManager from '@/ui/ps/comm/PSServiceManager'

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS263P', 
  screenId: 'MSPPS263P', 
  components: {
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    pItem: Object
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  //팝업 형태 속성
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      parntSrnId: null, // 부모 화면 ID
      showPopup: true,
      
      lv_data: {
        '1': {},
        '2': {},
        '3': {},
        '4': {},
        '5': {}
      },
      lv_spcChgDefCd: '1',
      lv_contrElstYn: 'N',  //계약자전자서명여부
      lv_insrdElstYn: 'N',  //피보험자전자서명여부
      spcObj: {},
      chgObj: {},
      loadComplete: false
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    this.fn_OnLoad()
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {},//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    /******************************************************************************
    * Function명 : fn_OnLoad
    * 설명       : 초기화 함수
    *            :
    ******************************************************************************/
    fn_OnLoad () {
      if (!this.pItem.vuchId) return
      this.fn_SetSpcChgDefCd()
      let lv_Vm = this
      let pParams = {
        vuchId: this.pItem.vuchId
      }
      const trnstId = 'txTSSPS90S4'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          console.log('fn_OnLoad->', response.body)
          let lst = response.body.pSSpcChgPtcpInfoSVOList
          for (let i in lst) {
            let ptcpInfoSVO = lst[i]
            console.log(lst)
            console.log(ptcpInfoSVO)
            console.log(ptcpInfoSVO)
            lv_Vm.lv_data[String(ptcpInfoSVO.contPtcpScCd)] = ptcpInfoSVO
          }
          lv_Vm.fn_SetProgress()
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_SetSpcChgDefCd
    * 설명       : 어떤 상태(특별조건부만/변경청약만/둘다)인지 확인
    *            :
    ******************************************************************************/
    fn_SetSpcChgDefCd () {
      this.spcObj = this.pItem.pSSpcObj
      this.chgObj = {}
      if (!_.isEmpty(this.pItem.pSChgLst)) {
        this.chgObj = this.pItem.pSChgLst.filter(a => a.mobpsUrlDspchYn === 'Y').reduce((a, b) => Number(a.aedat + a.aezet) > Number(b.aedat + b.aezet) ? a : b )
      }
      if (_.isEmpty(this.chgObj)) {
        this.lv_spcChgDefCd = '2' // 특별조건부만 있음.
      } else {
        if ((this.chgObj.aedat + this.chgObj.aezet) === (this.spcObj.aedat + this.spcObj.aezet)) {
          // 마지막 수정시간이 둘이 같은경우
          this.lv_spcChgDefCd = '3'
        } else {
          // 같지않은경우.
          if (Number(this.chgObj.aedat + this.chgObj.aezet) > Number(this.spcObj.aedat + this.spcObj.aezet)) {
            // 변경청약이 최후인 경우
            this.lv_spcChgDefCd = '1'
          } else {
            // 특별조건부가 최후인 경우
            this.lv_spcChgDefCd = '2'
          }
        }
      }
      console.log('fn_SetSpcChgDefCd', this.lv_spcChgDefCd)
    },

    /******************************************************************************
    * Function명 : fn_SetProgress
    * 설명       : 진행상태 초기화 함수
    *            :
    ******************************************************************************/
    fn_SetProgress () {
      if (this.lv_spcChgDefCd === '1') { // 변경 청약만 있음
        this.chgObj = {}
        if (!_.isEmpty(this.pItem.pSChgLst)) {
          this.chgObj = this.pItem.pSChgLst.filter(a => a.mobpsUrlDspchYn === 'Y').reduce((a, b) => Number(a.aedat + a.aezet) > Number(b.aedat + b.aezet) ? a : b )
        }
        this.lv_contrElstYn = this.chgObj.contrElstYn
        this.lv_insrdElstYn = this.chgObj.insrdElstYn
      } else {
        // 변청, 특별조건부 둘다 있음
        this.lv_contrElstYn = this.pItem.pSSpcObj.contrElstYn
        this.lv_insrdElstYn = this.pItem.pSSpcObj.insrdElstYn
      }
      this.loadComplete = true
    },

    /******************************************************************************
    * Function명 : fn_Send
    * 설명       : 알림톡발송 또는 바로열기버튼 클릭시
    *            : 
    ******************************************************************************/
    fn_Send (pContPtcpScCd = '1', pSendKaKao) {
      // 계약번호(vuchId), 계약관계인구분코드 contPtcpScCd(1~9), 일련번호 spcCndtlElstSno (1~N), 모바일영업전자서명구분코드 mobslElstScCd ( A, S, C 등), 화면명 prdtNm 화면에서 발송 txTSSPS90P1 <-서버이용 데이터.
      // elstPlanId: planId
      // 기존 사용데이터 취합해서 SVO하나 만들기.
      var lv_Vm = this
      let isSendKaKao = true
      if (pSendKaKao && pSendKaKao === '2') isSendKaKao = false
      var trnstId = 'txTSSPS90P1'
      let pSSpcChgListSVO1 = []
      let pSSpcChgListSVO2 = []
      if (lv_Vm.lv_spcChgDefCd === '1' || lv_Vm.lv_spcChgDefCd === '3') {
        let cData = {}
        let lv_Vm = this
        let pItem = this.pItem
        if (!pItem.hasC) cData = {} // c없으면 빈값
        let latestSendObj = pItem.lastSendObj
        if (!latestSendObj.mobpsUrlDspchTrtDtm) latestSendObj.mobpsUrlDspchTrtDtm = 0
        if (pItem.contStatCdNm === '작성중') {
          if (latestSendObj.mobpsUrlDspchTrtDtm === pItem.lastSendObjC.mobpsUrlDspchTrtDtm) {
            // 마지막 보낸 시간이 일치할때만
            cData = pItem.lastSendObjC
          } else {
            cData = {}
          }
        }
        if (!_.isEmpty(cData)) {
          let pParam = {
            vuchId: lv_Vm.pItem.vuchId, // 영수증번호 23663361148012900202
            contPtcpScCd: pContPtcpScCd, // 계약관계인 구분코드
            spcCndtlElstSno: lv_Vm.pItem.lastSendObjC.spcCndtlElstSno,
            mobslElstScCd: 'C',
            elstPlanId: lv_Vm.pItem.vuchId,
            ContNo: '',
            isSendKaKao: isSendKaKao ? '1' : '2'
          }
          pSSpcChgListSVO1.push(pParam)
        }
      }
      if (lv_Vm.lv_spcChgDefCd === '2' || lv_Vm.lv_spcChgDefCd === '3') {
        let sData = {}
        let pItem = this.pItem
        if (!pItem.hasS) sData = {} // c없으면 빈값
        let latestSendObj = pItem.lastSendObj
        if (pItem.contStatCdNm === '작성중' && latestSendObj.mobpsUrlDspchTrtDtm === pItem.pSSpcObj.mobpsUrlDspchTrtDtm) {
          // 작성중일때 마지막으로 보낸 객체와 시간이 같을때만 출력
          sData = pItem.pSSpcObj
        }
        if (!_.isEmpty(sData)) {
          let pParam = {
            vuchId: lv_Vm.pItem.vuchId, // 영수증번호 23663361148012900202
            contPtcpScCd: pContPtcpScCd, // 계약관계인 구분코드
            spcCndtlElstSno: lv_Vm.pItem.pSSpcObj.spcCndtlElstSno,
            mobslElstScCd: 'S',
            elstPlanId: lv_Vm.pItem.vuchId,
            ContNo: '',
            isSendKaKao: isSendKaKao ? '1' : '2'
          }
          pSSpcChgListSVO2.push(pParam)
        }
      }
      let pParams = {
        pSSpcChgListSVO1: pSSpcChgListSVO1,
        pSSpcChgListSVO2: pSSpcChgListSVO2,
        spcChgDefCd: lv_Vm.lv_spcChgDefCd
      }
      lv_Vm.post(lv_Vm, pParams, trnstId, 'S').then(function (response) {
        console.log(' 계약관계자 정보를 조회', response)
        console.log('fn_Send' + response.body)
        if ( response.body && response.msgComm) {
          if (response.msgComm.msgCd === 'EFWS003') {
            if (response.body.pSSpcChgListSVO1) {
              if (response.body.pSSpcChgListSVO1[0] && response.body.pSSpcChgListSVO1[0].isSendKaKao === '2') {
                lv_Vm.fn_OpenWebViewURL(response.body.pSSpcChgListSVO1[0].urlData)
              } else {
                lv_Vm.getStore('confirm').dispatch('ADD', '알림톡이 발송되었습니다.')
                lv_Vm.fn_Close()
              }
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']<br />' + response.msgComm.msgDesc)
          }
        } else {
          if (response && response.msgComm && response.msgComm.msgCd) {
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']<br />' + response.msgComm.msgDesc)
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '알림톡 발송에 실패하였습니다.')
          }
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },

    /************************************************************************************************
     * Function명  : fn_OpenWebViewURL
     * 설명        : 사랑On에서 WebView로 호출
     ************************************************************************************************/
    fn_OpenWebViewURL: function (pURL) {
      let serverChoice = process.env.NODE_ENV
      let targetUrl = pURL

      if (this.$commonUtil.isMobile()) {
        window.fdpbridge.exec('PhoneWebViewPlugin', {uri: targetUrl}, () => {}, () => {})
      } else {
        // 운영환경이 아닌 경우 get 방식으로 파라미터 호출
        if (serverChoice !== 'production') {
          window.open(targetUrl, '_blank')
        }
      }
    },

    /******************************************************************************
    * Function명 : fn_Close
    * 설명       : 종료 함수
    *            :
    ******************************************************************************/
    fn_Close: function () {
      this.$emit('closePopup')
    },

    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>