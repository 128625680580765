/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS262D
 * 화면 설명: 미결보완 계약 관계자 정보 확인
 */
<template>
  <!-- Content영역 -->
  <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">
    <ur-box-container alignV="start" componentid="" direction="column" class="pb50">
      <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="pal1624">
        <span class="fs22rem fwb">계약관계자 정보확인</span>
        <div><span class="fs20rem crTy-blue2 fwb mr2">2</span>/<span class="ml2">2</span></div>
      </ur-box-container>
      <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="bd-T-Ty1 h10px bgcolor-3"></ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list list-pa2024">
        <!-- 피보험자 -->
        <template v-if="contrInsrdSameYn !== 'Y'">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__title fexTy5 pb0">
                <span class="mr10 fwn fs14rem crTy-bk7">피보험자</span>
                <span class="name txtSkip">{{lv_insured.custNm}}</span>
              </div>
              <div class="list-item__contents__info pt0">
                <span class="crTy-bk2">{{ lv_insured.age }}세</span><em>|</em><span class="crTy-bk2">{{lv_insured.custRrnId1}} - {{lv_insured.custRrnId2}}</span><em>|</em><span class="crTy-bk2">{{lv_insured.custCelno}}</span>
              </div>
              <div class="list-item__contents__info pt17">
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
                  <mo-segment-wrapper solid primary v-model="urlTrnsTypInsured[0].key" class="chip-type-wrap">
                    <mo-segment-button class="h30px fs14rem pal0" v-for="(item, idx) in urlTrnsTypList" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
                  </mo-segment-wrapper>
                </ur-box-container>
              </div>
            </div>
          </mo-list-item>
        </template>
         <!-- 피보험자 -->

        <!-- 계약자 -->
        <!-- <template v-if="contrInsrdSameYn !== 'Y'"> -->
        <template>
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__title fexTy5 pb0">
                <span class="mr10 fwn fs14rem crTy-bk7" v-if="contrInsrdSameYn === 'Y'">피보험자 / 계약자</span>
                <span class="mr10 fwn fs14rem crTy-bk7" v-else>계약자</span>
                <span class="name txtSkip">{{lv_contr.custNm}}</span>
              </div>
              <div class="list-item__contents__info pt0">
                <span class="crTy-bk2">{{ lv_contr.age }}세</span><em>|</em><span class="crTy-bk2">{{lv_contr.custRrnId1}} - {{lv_contr.custRrnId2}}</span><em>|</em><span class="crTy-bk2">{{lv_contr.custCelno}}</span>
              </div>
              <div class="list-item__contents__info pt17">
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
                  <mo-segment-wrapper solid primary v-model="urlTrnsTypContr[0].key" class="chip-type-wrap">
                    <mo-segment-button class="h30px fs14rem pal0" v-for="(item, idx) in urlTrnsTypList" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
                  </mo-segment-wrapper>
                </ur-box-container>
              </div>
            </div>
          </mo-list-item>
        </template>
        <!-- 계약자 -->
      </ur-box-container>

    </ur-box-container>

    <template>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <!-- <mo-button componentid="mo_button_001" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="$emit('prevBtnClicked')">이전</mo-button> -->
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click.stop="$emit('closePop')">취소</mo-button>
          <!-- <mo-button componentid="mo_button_003" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Send" :disabled="!isAllCheckd">발송</mo-button> -->
          <mo-button componentid="mo_button_003" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Send">발송</mo-button>
        </div>
      </ur-box-container>
    </template>
  </ur-box-container>
   <!--/ root ur-page-container -->
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import PSElstUtil from '@/ui/ps/comm/PSElstUtil'

export default {

/***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS262D', 
  screenId: 'MSPPS262D',

  components: {
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    pItem: {
      type: Object,
      default: function () {
        return {}
      }
    },
    pNextObj: {
      type: Object,
      default: function () {
        return {
          cngObj: {},
          spcObj: {}
        }
      }
    }
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {            
      lv_elstPlanId: '',
      checkbox: {
        '1': false,
        '2': false,
        '9': true
      },
      DEBUG: process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development',
      lv_InputData: {}, // 발행 데이터
      hoshInfoList: '', // 계약자/피보험자 정보
      planInfoData: '', // 상품정보
      jsonString: '',
      standardAge19: 19, // 기준 나이 19 / 미성년자
      standardAge14: 14, // 기준 나이 14 / 본인서명불가 나이
      contrInsrdSameYn: 'N', // 계피상이여부
                  
      // 계약자
      lv_contr: {
        custNm: '',
        age: '',
        custRrnId: '',
        custRrnId1: '',
        custRrnId2: '',
        custCelno: '',
        chnlCustId: '',
        chnlCstgrId: '',
        showNameTooltip: false // 이름이 4자이상인 경우 toolTip표시
      },

      // 피보험자
      lv_insured: {
        custNm: '',
        age: '',
        custRrnId: '',
        custRrnId1: '',
        custRrnId2: '',
        chnlCustId: '',
        chnlCstgrId: '',
        showNameTooltip: false // 이름이 4자이상인 경우 toolTip표시
      },

      lv_fc: { // fc
        fcNm: '', // FC 이름
        fcCelno: '',
        showNameTooltip: false // 이름이 4자이상인 경우 toolTip표시
      },

      urlTrnsTypList: [ // URL전송유형 목록
        {
          key: 'K',
          label: '카카오톡'
        },
        {
          key: 'T',
          label: '태블릿'
        }
      ],
      //urlTrnsContrShow: false, // 계약자 청약URL표시여부
      urlTrnsTypContr: [{ // 계약자 URL전송유형
        key: 'K',
        label: '카카오톡'
      }],

      //urlTrnsInsuredShow: false, // 피보험자 청약URL표시여부
      urlTrnsTypInsured: [{ // 피보험자 URL전송유형
        key: 'K',
        label: '카카오톡'
      }],

      urlTrnsTypFC: [{ // FC URL전송유형
        key: 'K',
        label: '카카오톡'
      }],

    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {    
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

    let nObj = this.pNextObj.spcObj && this.pNextObj.spcObj.vuchId ? this.pNextObj.spcObj : this.pNextObj.cngObj && this.pNextObj.cngObj.vuchId ? this.pNextObj.cngObj : {}
    let pId = this.pNextObj.spcObj && this.pNextObj.spcObj.elstPlanId ? this.pNextObj.spcObj.elstPlanId : this.pNextObj.cngObj && this.pNextObj.cngObj.elstPlanId ? this.pNextObj.cngObj.elstPlanId : ''
    
    console.log('created pid:', pId)
    console.log('nObj', nObj)
    if (pId === '') {
      console.log()
      this.fn_GetElstPId(nObj.vuchId)
    } else {
      this.lv_elstPlanId = pId
      this.fn_SetDataFromElstPId(pId)
    }
    
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
    this.fn_Valid()

  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { return this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {
    // isAllCheckd () {
    //   if (this.urlTrnsContrShow && this.urlTrnsInsuredShow) {
    //     // 계/피 둘다 있을경우
    //     return this.checkbox['1'] && this.checkbox['2']
    //   } else if (this.urlTrnsContrShow) {
    //     // 계 있을경우
    //     return this.checkbox['1']
    //   } else if (this.urlTrnsInsuredShow) {
    //     // 피 있을경우
    //     return this.checkbox['2']
    //   }
    // }
  },//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {

  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/
    /******************************************************************************
    * Function명 : fn_GetElstPId
    * 설명       : 영수증ID를 통해 00002번 테이블의 전자서명설계ID 조회
    *            :
    ******************************************************************************/
    fn_GetElstPId (vuchId) {
      console.log('vuchId:', vuchId)
      let lv_Vm = this
      let pParams = {
        vuchId: vuchId
      }
      const trnstId = 'txTSSPS90S3'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (response.body && response.body.elstPlanId) { // FC 이름이 길어질 경우 tooltip표시 여부 확인
            let elstPlanId = response.body.elstPlanId
            lv_Vm.lv_elstPlanId = elstPlanId
            lv_Vm.fn_SetDataFromElstPId(elstPlanId)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_SetDataFromElstPId
    * 설명       : 심사결과 정보 호출
    ******************************************************************************/
    fn_SetDataFromElstPId (pId) {
      this.jsonString = JSON.stringify({tmodel: {searchCriteria: {zaPlanId: pId}}, vuchId: ' ', dpstrInfo: {chnlCustId: ''}})
      PSElstUtil.fn_EltrnSignBaseInfo('txTSSPS13S2', this.jsonString, this.fn_ServiceDataResultHandler)
    },
    
    /******************************************************************************
    * Function명 : fn_ServiceDataResultHandler
    * 설명       : 전자서명 초기 데이타 호출 결과 컨트롤 함수
    ******************************************************************************/
    fn_ServiceDataResultHandler: function (event) {
      if (event.hoshInfoList) {
        this.hoshInfoList = event.hoshInfoList // 계약자/피보험자 정보
        this.planInfoData = event.planInfoData // 상품정보

        // 계약자 정보 설정 hoshInfoList[0]
        this.lv_contr.custNm = this.hoshInfoList[0].insrdNm // 계약자 이름
        // if (this.lv_contr.custNm.length > 4) { // 계약자 이름이 길어질 경우 tooltip표시 여부 확인
        //   this.lv_contr.showNameTooltip = true
        // }
        this.lv_contr.age = this.hoshInfoList[0].age // 계약자 나이
        this.lv_contr.custRrnId = this.hoshInfoList[0].custRrnId // 계약자 주민번호
        this.lv_contr.custRrnId1 = this.hoshInfoList[0].custRrnId.substring(0, 6) // 계약자 주민번호 앞자리
        this.lv_contr.custRrnId2 = this.hoshInfoList[0].custRrnId.substring(6, 7) + '******' // 계약자 주민번호 뒷 한자리 + 마스킹
        this.lv_contr.custCelno = this.hoshInfoList[0].detail.celno // 계약자 전화번호

        this.lv_contr.chnlCustId = this.hoshInfoList[0].detail.chnlCustId // 계약자 채널고객ID
        this.lv_contr.chnlCstgrId = this.hoshInfoList[0].detail.chnlCstgrId // 계약자 채널그룹ID

        // 피보험자
        if (this.hoshInfoList[0].agtFileId !== this.hoshInfoList[1].agtFileId) { // 계피상이여부
          this.contrInsrdSameYn = 'N' // 계피상이
          // 피보험자 정보 설정 hoshInfoList[1]
          this.lv_insured.custNm = this.hoshInfoList[1].insrdNm // 피보험자 이름
          // if (this.lv_insured.custNm.length > 4) { // 계약자 이름이 길어질 경우 tooltip표시 여부 확인
          //   this.lv_insured.showNameTooltip = true
          // }
          this.lv_insured.age = this.hoshInfoList[1].age // 피보험자 나이
          this.lv_insured.custRrnId = this.hoshInfoList[1].custRrnId // 피보험자 주민번호
          this.lv_insured.custRrnId1 = this.hoshInfoList[1].custRrnId.substring(0, 6) // 피보험자 주민번호 앞자리
          this.lv_insured.custRrnId2 = this.hoshInfoList[1].custRrnId.substring(6, 7) + '******' // 피보험자 주민번호 뒷 한자리 + 마스킹
          this.lv_insured.custCelno = this.hoshInfoList[1].detail.celno // 계약자 전화번호

          this.lv_insured.chnlCustId = this.hoshInfoList[1].detail.chnlCustId // 피보험자 채널고객ID
          this.lv_insured.chnlCstgrId = this.hoshInfoList[1].detail.chnlCstgrId // 피보험자 채널그룹ID
        } else { // 계피동일
          this.contrInsrdSameYn = 'Y' // 계피동일
        }

        // 세대원목록 조회
        this.fn_selHouseholdMemberList()

        // fc정보 조회
        this.fn_SelFcInfo()
      } else { // if (hoshInfoList) { 종료
        console.log('#### fn_ServiceDataResultHandler 오류')
      }
    },

    /******************************************************************************
    * Function명 : fn_selHouseholdMemberList
    * 설명       : 세대원 목록 조회
    ******************************************************************************/
    fn_selHouseholdMemberList () {
      // 1. 세대원 목록 초기화
      this.householdMemberList = []
      this.householdMemberListNotContr = []

      // 2. 세대원 목록 조회
      let pParams = {
        'cnsltNo': this.getStore('userInfo').getters.getUserInfo.userEno, // 컨설턴트사번
        'chnlCstgrId': this.lv_contr.chnlCstgrId // 채널고객그룹ID
      }

      let trnstId = 'txTSSCM10S5' // 정보활용동의고객상세조회
      let auth = 'S'
      let lv_Vm = this

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (response.body !== null && response.body.iCCustInfoPrcusCnsntVO !== null) {
            // 2.1 세대원 목록 설정
            let t_CnsntList = response.body.iCCustInfoPrcusCnsntVO
            let i = 0
            let currentYmd = PSDateUtil.fn_CurrentDate()

            // 2.2 반복, 세대원 목록 구성
            t_CnsntList.forEach((item) => {
              let t_member = {}
              i++

              // 2.2.1 화면 표시영역
              t_member.key = i.toString()
              t_member.label = lv_Vm.$bizUtil.omitStr(item.custNm, 4)
              t_member.style = true // 필수컨설팅동의 : N

              // 2.2.2 데이터 영역
              t_member.name = item.custNm
              t_member.chnlCustId = item.chnlCustId
              t_member.knbEncr = item.knbEncr // 주민등록번호 암호화 : YYMMDD1******
              t_member.knbEncr1 = item.knbEncr.substring(0, 6)
              t_member.knbEncr2 = item.knbEncr.substring(6, 12)
              t_member.celno = item.celno
              t_member.isMcnstYn = 'N' // 필수컨설팅동의여부

              // 정보활용동의일자14 = 필수컨설팅동의일자
              if (item.infoPrcusCnsntYmd14 >= currentYmd) {
                t_member.style = false // 필수컨설팅동의 : Y
                t_member.isMcnstYn = 'Y' // 필수컨설팅동의여부
              }

              // 2.3 계약자의 채널고객 ID, 세대원의 채널고객ID가 일치 && 계약자가 미성년자가 아닌 경우
              //     예금주 초기값으로 설정
              if (lv_Vm.lv_contr.chnlCustId === t_member.chnlCustId && lv_Vm.$bizUtil.compareCurrentAge(lv_Vm.lv_contr.custRrnId.substr(0, 6), lv_Vm.standardAge19)) {
                lv_Vm.lv_dpstrSel = t_member
                lv_Vm.dPstrContrFmrtSel = {key: '01', label: '본인'}
              }

              // 가족 구성원 목록에 1건씩 추가
              lv_Vm.householdMemberList.push(t_member)

              // 가족 구성원 목록추가(계약자 제외)
              if (lv_Vm.lv_contr.chnlCustId !== t_member.chnlCustId) {
                lv_Vm.householdMemberListNotContr.push(t_member)
              }
            })
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
          // 청약url표시 검증
          lv_Vm.fn_urlTrnsShowCheck()
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    
    /******************************************************************************
    * Function명 : fn_SelFcInfo
    * 설명       : FC정보조회
    *            : FC전화번호는 getStore('userInfo')에 가지고 있지 않음
    ******************************************************************************/
    fn_SelFcInfo () {
      let lv_Vm = this
      let pParams = {userEno: this.getStore('userInfo').getters.getUserInfo.userId}
      const trnstId = 'txTSSBC20S3'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          let fc_UserVo = response.body
          lv_Vm.lv_fc.fcNm = fc_UserVo.userNm
          // if (lv_Vm.lv_fc.fcNm.length > 4) { // FC 이름이 길어질 경우 tooltip표시 여부 확인
          //   lv_Vm.lv_fc.showNameTooltip = true
          // }
          // 전화번호는 00011112222 형태이기때문에, -을 추가하기 위해서 util 호출
          lv_Vm.lv_fc.fcCelno = lv_Vm.$bizUtil.telNoWithHyphen(fc_UserVo.celno.trim())
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_urlTrnsShowCheck
    * 설명       : 청약 URL전송여부 표시
    ******************************************************************************/
    fn_urlTrnsShowCheck () {
      this.urlTrnsContrShow = false // 계약자 청약URL 표시
      this.urlTrnsInsuredShow = false // 피보험자 청약URL 표시
      
      // 계약자 : 14세 이상인 경우 발송
      if (this.$bizUtil.compareCurrentAge(this.lv_contr.custRrnId.substr(0, 6), this.standardAge14) === true) {
        this.urlTrnsContrShow = true
        this.checkbox['1'] = true
      }

      // 피보험자 : 계약자 != 피보험자 && 14세 이상인 경우 발송
      if (this.contrInsrdSameYn !== 'Y' &&
          this.$bizUtil.compareCurrentAge(this.lv_insured.custRrnId.substr(0, 6), this.standardAge14) === true) {
        this.urlTrnsInsuredShow = true
        this.checkbox['2'] = true
      }
    },
    /************************************************************************************************
     * Function명  : fn_ServiceResultHandler
     * 설명        : 서비스 호출 결과 함수 정의
     ************************************************************************************************/
    fn_ServiceResultHandler: function (event, serviceName) {
      let lv_data = event.data
      switch (serviceName) {
        case 'S5': // 서버에 이미 서명완료되었는지 확인
          let eltrnSignCmpltPhseTrtVO = Object.assign({}, lv_data)
          console.log('handler S5', eltrnSignCmpltPhseTrtVO)
          let eltrnSignPhseCd01 = eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd01.trim() // 전자문서보관

          if (!eltrnSignPhseCd01 || eltrnSignPhseCd01 === 'N') {
            //this.isOnServer = false
          } else {
            this.fn_BottomAlert("이미 서명 완료되었습니다")
          }
          break
        default:
          break
      }
    },

    /************************************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 에러 이벤트
     ************************************************************************************************/
    fn_ServiceFaultHandler: function (event, serviceName) {
      switch (serviceName) {
        case 'S5':
          this.isMobpsValMessge = '고객서명정보 조회시 오류가 발생하였습니다.'
          break
        default:
          break
      }
      this.isMobpsValCheck = true
    },

    /******************************************************************************
    * Function명 : fn_BottomAlert
    * 설명       : 알림 팝업 호출
    ******************************************************************************/
    fn_BottomAlert (contents) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         single: true,
         title: '알림',
         content: contents
       },
       listeners: {
         onPopupConfirm: () => {
           this.$emit('prevBtnClicked')
           this.$bottomModal.close(this.lv_AlertPop);
         },
         onPopupClose: () => {
           this.$emit('prevBtnClicked')
           this.$bottomModal.close(this.lv_AlertPop);
           }
         }
      })
    },
    
    /******************************************************************************
    * Function명  : fn_Valid
    * 설명        : 이미 서명완료되었는지 확인
    ******************************************************************************/
    fn_Valid () {
      let pParams = {}
      pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS31S23'))
      pParams.data.eltrnDoctId = this.pItem.vuchId + this.fn_GetDocElstNo()
      pParams.srnId = this.$options.screenId // 대표 화면 명
      PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, 'S5', this.fn_ServiceFaultHandler)
    },
    /******************************************************************************
    * Function명  : fn_GetDocElstNo
    * 설명        : 전자문서ID 추출
    ******************************************************************************/
    fn_GetDocElstNo () {
      let chgNum = _.isEmpty(this.pNextObj.cngObj) ? '00000' : this.$bizUtil.lpad(this.pNextObj.cngObj.spcCndtlElstSno, 5, '0')
      let spcNum = _.isEmpty(this.pNextObj.spcObj) ? '00000' : this.$bizUtil.lpad(this.pNextObj.spcObj.spcCndtlElstSno, 5, '0')
      return chgNum + spcNum
    },
    /******************************************************************************
    * Function명  : fn_Send
    * 설명        : 발송버튼 클릭시 호출
    ******************************************************************************/
    fn_Send () {
      // 계약번호(vuchId), 계약관계인구분코드 contPtcpScCd(1~9), 일련번호 spcCndtlElstSno (1~N), 모바일영업전자서명구분코드 mobslElstScCd ( A, S, C 등), 화면명 prdtNm 화면에서 발송 txTSSPS90P1 <-서버이용 데이터.
      // elstPlanId: planId
      // 기존 사용데이터 취합해서 SVO하나 만들기.
      var lv_Vm = this
      if (( this.contrInsrdSameYn === 'Y' && !this.checkbox['1']) || (!this.checkbox['1'] && !this.checkbox['2'])) {
        return
      }

      var trnstId = 'txTSSPS90P1'
      let pSSpcChgListSVO1 = []
      let pSSpcChgListSVO2 = []
      let usingList = []
      if (lv_Vm.pNextObj.spcChgDefCd === '1') {
        usingList = pSSpcChgListSVO1
      } else if (lv_Vm.pNextObj.spcChgDefCd === '2') {
        usingList = pSSpcChgListSVO2
      } else {
        usingList = pSSpcChgListSVO1
      }
      
      let pParam = {
        vuchId: this.pItem.planId,
        elstPlanId: _.isEmpty(this.lv_elstPlanId) ? this.pItem.vuchId : this.lv_elstPlanId
      }
      for (let i in this.checkbox) {
        if (!this.checkbox[i]) continue
        pParam.contPtcpScCd = i // 계약관계인 구분코드
        if (i === '1') {
          pParam.elstPartnNo = this.hoshInfoList[0].partnerId
          pParam.mobslChnlCustId = this.hoshInfoList[0].agtFileId
          pParam.mobslChnlCstgrId = this.hoshInfoList[0].detail.chnlCstgrId // 모바일영업채널고객그룹ID
          pParam.contPtcpTelnoEncr = this.$bizUtil.replaceAll(this.hoshInfoList[0].detail.celno, '-', '') // 계약관계인전화번호암호화
          pParam.contPtcpRrnEncr = this.hoshInfoList[0].custRrnId // 계약관계인주민등록번호암호화
          pParam.mobslChnlCustNm = this.hoshInfoList[0].insrdNm // 모바일영업채널고객명
          pParam.mobpsContrRltnScCd = '01' // 모바일청약계약자관계구분코드 / 01:본인, 02:배우자, 03:부모, 04:자녀
          pParam.mobpsSignMthCd = this.urlTrnsTypContr[0].key // 모바일청약서명방법코드
          pParam.isSendKaKao = this.urlTrnsTypContr[0].key === 'K' ? '1' : '2'
        }
        if (i === '2') {
          pParam.elstPartnNo = this.hoshInfoList[1].partnerId
          pParam.mobslChnlCustId = this.hoshInfoList[1].agtFileId
          pParam.mobslChnlCstgrId = this.hoshInfoList[1].detail.chnlCstgrId // 모바일영업채널고객그룹ID
          pParam.contPtcpTelnoEncr = this.$bizUtil.replaceAll(this.hoshInfoList[1].detail.celno, '-', '') // 계약관계인전화번호암호화
          pParam.contPtcpRrnEncr = this.hoshInfoList[1].custRrnId // 계약관계인주민등록번호암호화
          pParam.mobslChnlCustNm = this.hoshInfoList[1].insrdNm // 모바일영업채널고객명
          pParam.mobpsContrRltnScCd = ' ' // 모바일청약계약자관계구분코드 / 01:본인, 02:배우자, 03:부모, 04:자녀
          pParam.mobpsSignMthCd = this.urlTrnsTypInsured[0].key // 모바일청약서명방법코드
          pParam.isSendKaKao = this.urlTrnsTypInsured[0].key === 'K' ? '1' : '2'
        }
        if (i === '9') {
          pParam = {}
          pParam.vuchId = this.pItem.planId
          pParam.elstPlanId = _.isEmpty(this.lv_elstPlanId) ? this.pItem.vuchId : this.lv_elstPlanId
          pParam.mobpsContrRltnScCd = ' ' // 모바일청약계약자관계구분코드 / 01:본인, 02:배우자, 03:부모, 04:자녀
          pParam.contPtcpScCd = '9' // 계약관계인구분코드 / 9:FC
          pParam.contPtcpTelnoEncr = this.$bizUtil.replaceAll(this.lv_fc.fcCelno, '-', '') // 계약관계인전화번호암호화
          pParam.mobslChnlCustNm = this.lv_fc.fcNm // 모바일영업채널고객명
          pParam.mobpsSignMthCd = this.urlTrnsTypFC[0].key // 모바일청약서명방법코드
          pParam.coprPrtrMuagrYn = 'N' // 공동친권합의여부
          pParam.kakaoIapDspchSn = '2' // 카카오톡알림발송순번
          pParam.isSendKaKao = '2'
        }
        if (lv_Vm.pNextObj.spcChgDefCd === '1' || lv_Vm.pNextObj.spcChgDefCd === '3') {
          pParam.spcCndtlElstSno = lv_Vm.pNextObj.cngObj.spcCndtlElstSno
          pParam.mobslElstScCd = 'C'
          pSSpcChgListSVO1.push(Object.assign({}, pParam))
        }
        if (lv_Vm.pNextObj.spcChgDefCd === '2' || lv_Vm.pNextObj.spcChgDefCd === '3') {
          pParam.spcCndtlElstSno = lv_Vm.pNextObj.spcObj.spcCndtlElstSno
          pParam.mobslElstScCd = 'S'
          pSSpcChgListSVO2.push(Object.assign({}, pParam))
        }
      }
      if (usingList.length === 0) {
        lv_Vm.getStore('confirm').dispatch('ADD', '계약관계자가 선택되지 않았습니다.')
        return
      }
      let pParams = {
        pSSpcChgListSVO1: pSSpcChgListSVO1,
        pSSpcChgListSVO2: pSSpcChgListSVO2,
        spcChgDefCd: lv_Vm.pNextObj.spcChgDefCd
      }
      console.log(pParams)
      // if (this.DEBUG) return
      lv_Vm.post(lv_Vm, pParams, trnstId, 'S').then(function (response) {
        console.log(' 계약관계자 정보를 조회', response)
        console.log('fn_Send' + response.body)
        if ( response.body && response.msgComm) {
          if (response.msgComm.msgCd === 'EFWS003') {
            if (response.body) {
              if (lv_Vm.pNextObj.spcChgDefCd === '1' || lv_Vm.pNextObj.spcChgDefCd === '3') {
                if (response.body.pSSpcChgListSVO1) {
                  for (let i = 0; i < response.body.pSSpcChgListSVO1.length; i++) {
                    if ( response.body.pSSpcChgListSVO1[i].wkRslt === '0') { // 정상발송 0
                    } else { // 비정상케이스
                      lv_Vm.getStore('confirm').dispatch('ADD', '알림톡 발송에 실패하였습니다.')
                      return
                    }
                  }
                }
              }
              if (lv_Vm.pNextObj.spcChgDefCd === '2' || lv_Vm.pNextObj.spcChgDefCd === '3') {
                if (response.body.pSSpcChgListSVO2) {
                  for (let i = 0; i < response.body.pSSpcChgListSVO2.length; i++) {
                    if ( response.body.pSSpcChgListSVO2[i].wkRslt === '0') { // 정상발송 0
                    } else { // 비정상케이스
                      lv_Vm.getStore('confirm').dispatch('ADD', '알림톡 발송에 실패하였습니다.')
                      return
                    }
                  }
                }
              }
              // 비정상 케이스 없었으므로 화면종료.
              if (lv_Vm.urlTrnsTypContr[0].key === 'K' || lv_Vm.urlTrnsTypInsured[0].key === 'K') lv_Vm.getStore('confirm').dispatch('ADD', '고객님에게 전송이 완료되었습니다.')
              lv_Vm.$emit('closePop')
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']<br />' + response.msgComm.msgDesc)
          }
        } else {
          if (response && response.msgComm && response.msgComm.msgCd) {
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']<br />' + response.msgComm.msgDesc)
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '알림톡 발송에 실패하였습니다.')
          }
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    
    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>