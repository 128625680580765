/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS261P
 * 화면 설명: 특별조건부 전자서명 완료 재처리 (미결진행 재처리)
 */
 <template>
  <ur-page-container class="msp" title="미결진행 재처리" :show-title="true" type="subpage">
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box pb0" nowrap>
          <ur-box-container alignV="start" componentid="" direction="column" class="row-box bd-b-Ty2 bd-T-Ty1 mt30 pt16 pb11">
            <span class="w65px mr15 fs16rem fex-clumn fexJsCtTy4 h100"><span>상품명</span></span>
            <span class="flex-1 fs17rem fwb">{{rowPopupData.prdtNm}}</span>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="row-box bd-b-Ty2 pt16 pb11">
            <span class="w65px mr15 fs16rem fex-clumn fexJsCtTy4 h100"><span>계약자</span></span>
            <span class="flex-1 fs17rem fwb">{{rowPopupData.custNm}}</span>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="row-box bd-b-Ty1 pt16 pb11">
            <span class="w65px mr15 fs16rem fex-clumn fexJsCtTy4 h100"><span>청약일</span></span>
            <span class="flex-1 fs17rem fwb">{{rowPopupData.contYmd.substring(0,10)}}</span>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="mt20">
            <ur-box-container v-for="(list, idx) in levelCompletion" :key="idx" alignV="start" componentid="" direction="column" class="row-box mb10">
              <div class="fexTy3 text-blue-box crTy-bk7" :class="{'bgcolor-1': !list.completion}">
                <div class="">{{list.step}}단계<span class="ml6 crTy-blue4 fwm">{{list.title}}</span></div>
                <div v-if="list.completion" class="ns-check wauto">
                  <mo-icon icon="msp-is-checked" class="fs26"></mo-icon><span class="ml4 crTy-blue3">완료</span>
                </div>
                <mo-button v-else color="normal" shape="border" size="small" class="blue bgcolor-2" @click="fn_LevelCompletion(list.step)">재처리</mo-button>
              </div>
            </ur-box-container>
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Close">확인</mo-button>
            </div>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager'

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS261P', 
  screenId: 'MSPPS261P', 
  components: {
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    rowPopupData: Object
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  //팝업 형태 속성
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      serverChoice: '', // 서버 상태(개발 development, 검증 stage, 운영 production)
      eltrnSignPhseCd: '',
      eltrnSignCmpltPhseTrtVO: {},
      levelCompletion: [
        {step: '1', title: '전자문서보관', completion: false},
        {step: '2', title: '주임메일', completion: false},
        {step: '3', title: '서류이메일', completion: false},
        {step: '4', title: '이미지스캔', completion: false}
      ]
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

    this.fn_Init()
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
     // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    /******************************************************************************
    * Function명 : fn_Init
    * 설명       : 초기화
    ******************************************************************************/
    fn_Init: function () {
      this.serverChoice = process.env.NODE_ENV // 현재 접속 앱 설정(개발 development,검증 stage,운영)
      console.log('##### MSPPS261P > fn_Init >>> this.serverChoice ===> ' + this.serverChoice)
      console.log('##### MSPPS261P > fn_Init >>> this.rowPopupData ===> ', this.rowPopupData)

      this.eltrnSignPhseCd = ''
      this.eltrnSignCmpltPhseTrtVO = {}
      this.fn_ServiceData('S1')
    },

    /******************************************************************************
    * Function명  : fn_ServiceData
    * 설명        : 서비스 호출 함수 정의
    ******************************************************************************/
    fn_ServiceData: function (serviceName) {
      let pParams = {}
      //this.isLoadingStatus = true
      switch (serviceName) {
        case 'S1': // 특별조건부 전자서명 단계별 처리 조회
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS31S23'))
          pParams.data.eltrnDoctId = this.fn_GetDocElstNo()
          break
        case 'I2': // 특별조건부 전자서명 단계 내역 저장
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS31I23'))
          // pParams.data = this.eltrnSignCmpltPhseTrtVO // 다른파라미터 필요없이 아래 4개만 필요
          pParams.data.moblBusnScCd = 'NB' // 모바일업무구분코드
          pParams.data.eltrnDoctScCd = '11' // 전자문서구분코드
          pParams.data.eltrnDoctId = this.fn_GetDocElstNo()
          pParams.data.eltrnSignPhseCd = this.eltrnSignPhseCd // 재처리 단계
          break
        case 'U9': // 특별조건부 전자서명체결 완료처리 변경.
          pParams = {}
          pParams.appId = 'tss.ps'
          pParams.trnstId = 'txTSSPS90U1'
          pParams.fnctScCd = 'U'
          pParams.serviceId = ''
          pParams.reqSrvcNm = 'PSSpcChgSVCImpl'
          pParams.reqMthdNm = 'updCmpltYnAftrReprcss'
          pParams.reqVONm = 'sli.tss.ps.eltrnsign.svc.svo.PSSpcChgSVO'
          pParams.resVONm = 'sli.tss.ps.eltrnsign.svc.svo.PSSpcChgSVO'
          pParams.srnId = ''
          pParams.data = {}
          let docId = String(this.fn_GetDocElstNo())
          pParams.data.vuchId = docId.slice(0, 20)
          pParams.data.docId = docId
          break
        default:
          break
      }
      pParams.srnId = this.$options.screenId // 대표 화면 명
      PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)
    },
    /************************************************************************************************
     * Function명  : fn_ServiceDataResultHandler
     * 설명        : 서비스 호출 결과 함수 정의
     ************************************************************************************************/
    fn_ServiceResultHandler: function (event, serviceName) {
      let lv_data = event.data

      switch (serviceName) {
        case 'S1': // 전자서명 단계별 처리 조회
          this.eltrnSignCmpltPhseTrtVO = Object.assign({}, lv_data)
          let eltrnSignPhseCd01 = this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd01.trim() // 전자문서보관
          let eltrnSignPhseCd02 = this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd02.trim() // 신계약정보전송
          let eltrnSignPhseCd03 = this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd03.trim() // 주임메일
          let eltrnSignPhseCd04 = this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd04.trim() // 서류이메일

          if (!eltrnSignPhseCd01 || eltrnSignPhseCd01 === 'N') {
            this.levelCompletion[0].completion = false
            this.levelCompletion[1].completion = false
            this.levelCompletion[2].completion = false
            this.levelCompletion[3].completion = false
          } else {
            this.levelCompletion[0].completion = true // 전자문서보관
            if (!eltrnSignPhseCd02 || eltrnSignPhseCd02 === 'N') { // 신계약정보전송
              this.levelCompletion[1].completion = false
            } else {
              this.levelCompletion[1].completion = true
            }
            if (!eltrnSignPhseCd03 || eltrnSignPhseCd03 === 'N') { // 주임메일
              this.levelCompletion[2].completion = false
            } else {
              this.levelCompletion[2].completion = true
            }
            if (!eltrnSignPhseCd04 || eltrnSignPhseCd04 === 'N') { // 서류이메일
              this.levelCompletion[3].completion = false
            } else {
              this.levelCompletion[3].completion = true
            }

            // 전자서명 리스트 저장값과 문서변환 정보 값이 상이 체크
            this.fn_PhseCompletion()

            if (this.eltrnSignCmpltPhseTrtVO) {
              this.eltrnSignCmpltPhseTrtVO.trtDtm = this.eltrnSignCmpltPhseTrtVO.trtDtm.substring(0, 10).replace('/', '-')
            } // end if
          }
          break
        case 'I2': // 전자서명 단계 내역 저장
          if (lv_data.wkRslt === 0) {
            if (this.eltrnSignPhseCd === '01') {
              this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd01 = 'Y'
              this.levelCompletion[0].completion = true
            } else if (this.eltrnSignPhseCd === '02') {
              this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd02 = 'Y'
              this.levelCompletion[1].completion = true
            } else if (this.eltrnSignPhseCd === '03') {
              this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd03 = 'Y'
              this.levelCompletion[2].completion = true
            } else if (this.eltrnSignPhseCd === '04') {
              this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd04 = 'Y'
              this.levelCompletion[3].completion = true
            }

            if (this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd01 !== 'N' && this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd02 !== 'N' &&
              this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd03 !== 'N' && this.eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd04 !== 'N') {
            }
          } else {
            this.getStore('confirm').dispatch('ADD', this.$t('ps')['EPSC012']) // '전자서명 재처리가 실패했습니다.')
          }
          break
        case 'U9': // 전자서명 완료여부 저장
          this.$emit('closePopup', this.levelCompletion)
          break
        default:
          break
      }
      //this.isLoadingStatus = false
      //this.isLoading = false
    },

    /************************************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 에러 이벤트
     ************************************************************************************************/
    fn_ServiceFaultHandler: function (event, serviceName) {
      this.getStore('confirm').dispatch('ADD', event.msgDesc)
    },
    
    /************************************************************************************************
     * Function명  : fn_GetDocElstNo
     * 설명        : 가장 최근의 전자서명 데이터 ID계산
     ************************************************************************************************/
    fn_GetDocElstNo: function () {
      let spcObj = this.rowPopupData.pSSpcObj
      let chgObj = this.rowPopupData.lastSendObjC
      let vuchId = this.rowPopupData['vuchId']
      if (this.rowPopupData.hasC && !_.isEmpty(chgObj.vuchId)) vuchId = chgObj.vuchId
      if (this.rowPopupData.hasS && !_.isEmpty(spcObj.vuchId)) vuchId = spcObj.vuchId
      let lastSentDtm = this.rowPopupData.lastSentDtm
      let spcElstSno = _.isEmpty(spcObj.spcCndtlElstSno) ? '0' : spcObj.spcCndtlElstSno
      let chgElstSno = _.isEmpty(chgObj.spcCndtlElstSno) ? '0' : chgObj.spcCndtlElstSno
      if (String(spcObj.mobpsUrlDspchTrtDtm) === String(lastSentDtm)) {
        // 같으면 ㅇ 아니면 x
        spcElstSno = _.isEmpty(spcObj.spcCndtlElstSno) ? '0' : spcObj.spcCndtlElstSno
      } else {
        spcElstSno = '0'
      }
      if (String(chgObj.mobpsUrlDspchTrtDtm) === String(lastSentDtm)) {
        // 같으면 ㅇ 아니면 x
        chgElstSno = _.isEmpty(chgObj.spcCndtlElstSno) ? '0' : chgObj.spcCndtlElstSno
      } else {
        chgElstSno = '0'
      }
      return vuchId + this.$bizUtil.lpad(chgElstSno, 5, '0') + this.$bizUtil.lpad(spcElstSno, 5, '0')
    },

    /************************************************************************************************
     * Function명  : fn_LevelCompletion
     * 설명        : 재처리
     ************************************************************************************************/
    fn_LevelCompletion: function (step) {
      this.eltrnSignPhseCd = '0' + step
      this.fn_ServiceData('I2')
    },

     /************************************************************************************************
     * Function명  : fn_Close
     * 설명        : 팝업닫기
     ************************************************************************************************/
    fn_Close: function () {
      let isAllClear = true
      for (let i = 0; i < 4; i++) {
        if (!this.levelCompletion[i].completion) {
          isAllClear = false
        }
      }
      
      if (isAllClear) {
        console.log('U9 start')
        this.fn_ServiceData('U9')
      } else {
        this.$emit('closePopup', this.levelCompletion)
      }
    },

    /************************************************************************************************
     * Function명  : fn_PhseCompletion
     * 설명        : 전자서명 단계 처리 체크 및 저장
     ************************************************************************************************/
    fn_PhseCompletion: function () {
      // let phseCmpltYn = false
      // 모두 완료 처리 일때
      if (this.levelCompletion[0].completion && this.levelCompletion[1].completion &&
          this.levelCompletion[2].completion && this.levelCompletion[3].completion &&
          this.rowPopupData['elst4PhseCmpltYn'] !== 'Y') {
        this.rowPopupData['elst2PhseCmpltYn'] = 'Y' // 전자서명완료2단계완료여부
        this.rowPopupData['elst4PhseCmpltYn'] = 'Y'// 전자서명완료4단계완료여부
        // phseCmpltYn = true
      } else if (this.levelCompletion[0].completion && this.levelCompletion[1].completion &&
          this.rowPopupData['elst2PhseCmpltYn'] !== 'Y') {
        this.rowPopupData['elst2PhseCmpltYn'] = 'Y' // 전자서명완료2단계완료여부
        this.rowPopupData['elst4PhseCmpltYn'] = 'N'// 전자서명완료4단계완료여부
        // phseCmpltYn = true
      }
    },

    
    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>