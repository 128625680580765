/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS261D
 * 화면 설명: 미결보완 상품정보 및 신청서 확인
 */
<template> 
  <!-- Content영역 -->
  <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">
    <ur-box-container alignV="start" componentid="" direction="column" class="pb50">
      <template v-if="pItem.contStatCdNm === '작성대상'">
        <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="pal1624">
          <span class="fs22rem fwb">{{pItem.contStatCdNm}}</span>
          <div><span class="fs20rem crTy-blue2 fwb mr2">1</span>/<span class="ml2">2</span></div>
        </ur-box-container>
        <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="bd-T-Ty1 h10px bgcolor-3"></ur-box-container>
        <ur-box-container direction="column" alignV="start" class="pa2024 bd-b-Ty1"> 
          <ul class="bullet-type-3 crTy-bk7 fs14rem">
            <li><span class="crTy-orange2">[미리보기]</span>를 통해 미결보완 필요내용이 맞는지 <span class="crTy-orange2">반드시 확인 후</span> 발송 부탁드립니다.</li>
          </ul>
        </ur-box-container>
      </template>
      <ur-box-container direction="column" alignV="start" class="pa2024"> 
        <div class="crTy-bk7 fs14rem">상품명</div>
        <div class="mt10">
          <b class="txtSkip fs19rem">{{pItem.prdtNm}}</b>            
        </div>
      </ur-box-container>        
      <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="bd-T-Ty1 h10px bgcolor-3"></ur-box-container>

      <ur-box-container direction="column" alignV="start" class="pa2024 pb10 bd-b-Ty1"> 
        <div class="crTy-bk7 fs14rem">신청서</div>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list pa1624-list">
        <mo-list-item v-if="JSON.stringify(lv_SpcData) !== '{}'">
          <mo-checkbox v-model="checkbox1" :disabled="pItem.contStatCdNm === '작성중'"></mo-checkbox>
          <div class="list-item__contents">
            <div class="list-item__contents__title fexTy5 mb0">
              <span class="name fwn fs16rem fexTy5">
                <span class="w150px txtSkip">특별조건부 특약신청서</span>

                <span class="crTy-blue4" v-if="pItem && pItem.pSSpcObj && pItem.pSSpcObj.mobpsUrlDspchTrtDtm === latestSendObj.mobpsUrlDspchTrtDtm && latestSendObj.mobpsUrlDspchYn === 'Y'  && (pItem.pSSpcObj.contrElstYn !== 'Y' || pItem.pSSpcObj.insrdElstYn !== 'Y')">작성중</span>
                <span class="crTy-blue4" v-else-if="pItem && pItem.pSSpcObj && pItem.pSSpcObj.mobpsUrlDspchTrtDtm === latestSendObj.mobpsUrlDspchTrtDtm && latestSendObj.mobpsUrlDspchYn === 'Y'  && (pItem.pSSpcObj.contrElstYn === 'Y' && pItem.pSSpcObj.insrdElstYn === 'Y')">작성완료</span>
              </span>
              <mo-button class="ns-btn-round h28 blue-line fs14rem" @click="fn_ClickPreview('S1')">미리보기</mo-button>
            </div>
            <!-- 2023.02.14 특별조건부 특약신청서에 날짜 추가됨 -->              
            <div class="list-item__contents__info pal0">
              <span class="crTy-bk2">{{$bizUtil.dateDashFormat(lv_SpcData.erdat)}} {{fn_Time(lv_SpcData.erzet)}}</span>
            </div><!-- // 2023.02.14 특별조건부 특약신청서에 날짜 추가됨 Add -->
          </div>  
        </mo-list-item>  

        <mo-list-item v-if="lv_ChgData.length > 0">
          <mo-checkbox v-model="checkbox2"/>
          <div class="list-item__contents">
            <div class="list-item__contents__title fexTy5 mb4">
              <span class="name txtSkip fwn fs16rem">보험계약 변경청약 신청서</span>
            </div>
            <div class="list-item__contents__info pal0" v-if="pItem.contStatCdNm === '작성대상'">
              <ul class="bullet-type-3 crTy-bk7 fs14rem ">
                <li class="word-break-all">고지우량체 변경청약의 경우 ‘보험료할인충족확인서'가 같이 발송됩니다.</li>
              </ul>  
            </div>  
          </div>  
        </mo-list-item> 
      </ur-box-container>

      <ur-box-container v-if="lv_ChgData.length > 0" direction="column" alignV="start" class="msp-sa-wrap"> 
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-radio-list2 list-pa2024">
          <mo-list :list-data="lv_ChgData.slice(0, 10)">
            <template #list-item="{item}">
              <mo-list-item :class="{'on' : item.spcCndtlElstSno === lv_selectItem.spcCndtlElstSno}">
                <mo-radio v-model="selectItems.spcCndtlElstSno" :disabled="!checkbox2" :value="item.spcCndtlElstSno" v-if="Number(item.spcCndtlElstSno) > lv_LastUseSno" @input="fn_ListItemRadioSlctHandler(item)" />
                <div class="list-item__contents">
                  <div class="list-item__contents__info mt0">
                    <span class="w100px hauto fs14rem line-h">변경 후 보험료</span>
                    <!--contPrm 내 $ 포함되어서 옴.-->
                    <span class="fwb crTy-bk1 flex-1" v-if="item.zaCrncyCd === 'KRW' || item.crncyCd === 'KRW'">{{item.contPrm}}원</span>
                    <span class="fwb crTy-bk1 flex-1" v-else>{{item.contPrm}}</span>
                  </div>
                  <div class="list-item__contents__info mt4">
                    <span class="w100px hauto fs14rem line-h">변경청약서류<br/>발행시간</span>
                    <span class="crTy-bk2 flex-1">{{$bizUtil.dateDashFormat(item.erdat) + ' ' +fn_Time(item.erzet)}}</span>
                  </div>
                  <div class="list-item__contents__info mt4">
                    <span class="w100px hauto fs14rem line-h">진행상태</span>
                    <span class="crTy-bk1 flex-1" v-if="item.mobpsUrlDspchTrtDtm === latestSendObj.mobpsUrlDspchTrtDtm && item.mobpsUrlDspchYn === 'Y' && (item.contrElstYn !== 'Y' || item.insrdElstYn !== 'Y')">작성중</span>
                    <span class="crTy-bk1 flex-1" v-else-if="item.mobpsUrlDspchTrtDtm === latestSendObj.mobpsUrlDspchTrtDtm && item.mobpsUrlDspchYn === 'Y' && (item.contrElstYn === 'Y' && item.insrdElstYn === 'Y')">작성완료</span>
                    <span class="crTy-bk1 flex-1" v-else>{{item.contStatCdNm}}</span>
                  </div>
                  <div class="list-item__contents__info mt4 fexTy3">
                    <span class="w100px hauto fs14rem line-h">완료일</span>
                    <span class="crTy-bk1 flex-1" v-if="item.contStatCdNm === '작성완료'">{{item.aedat + item.aezet}}</span>
                    <span class="crTy-bk1 flex-1" v-else>-</span>
                    <mo-button class="ns-btn-round h28 blue-line fs14rem" @click="fn_ClickPreview('S1', item)" :disabled="!(Number(item.spcCndtlElstSno) >= lv_LastUseSno) || !checkbox2">미리보기</mo-button> 
                  </div>
                </div>  
              </mo-list-item>
            </template>
          </mo-list>   
        </ur-box-container>
      </ur-box-container>  
    </ur-box-container>
    <!-- Content 영역 end -->
    <template>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div" v-if="pItem.contStatCdNm !== '작성대상'">
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="$emit('closePop')">확인</mo-button>
        </div>
        <div class="relative-div" v-if="pItem.contStatCdNm === '작성대상'">
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round white" @click="$emit('closePop')">취소</mo-button>
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Confirm()" :disabled="!canGoNext">다음</mo-button>
        </div>
      </ur-box-container>
    </template>
  </ur-box-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 

export default {

/***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS261D', 
  screenId: 'MSPPS261D',

  components: {},//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    pItem: {
      type: Object,
      default: function () {
        let tmp = {}
        tmp.pSChgLst = []
        return tmp
      }
    }
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {            
      isNodata: false, // 데이터 없음 Flag
      checkbox1: false,
      checkbox2: false,
      isConfirm2: false,
      lv_selectItem: {},
      lv_LastUseSno: -1,
      selectItems: {},
      rowPopupData: {},
      lv_Lst: [],
      lastChgObj: {},
      lastSpcObj: {},
      latestSendObj: {},
      checkItems: [
        {idx: 0, label: '특별조건부 특약신청서', value: '1'},
        {idx: 1, label: '마케팅 동의', value: '2'}
      ],
      rsltItemList : [] // 조회결과 리스트 데이터
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {    
    // backButton event 등록
    //window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
    
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

    this.fn_OnLoad()

  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    //window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { return this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    checkbox2 (o) {
      if (o) {
        if (this.lv_ChgData.length === 1) {
          this.selectItems = this.lv_ChgData.slice(0, 10)[0]
          this.lv_selectItem = this.lv_ChgData.slice(0, 10)[0]
        }
      }
      if (!o) {
        this.$set(this, 'selectItems', {})
        this.$set(this, 'lv_selectItem', {})
      }
    },
    // lv_selectItem (o) {
    //   this.selectItems = this.lv_selectItem
    //   this.itemRadioSlctVal = this.lv_selectItem.spcCndtlElstSno
    // }
  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {
    canGoNext () {
      // 둘다선택 해야만 넘어가는 로직
      let ret = false
      // let pItem = this.pItem
      // if (pItem.contStatCdNm === '작성중') {
      //   ret = false
      // } else if (!_.isEmpty(this.lv_SpcData) && !this.checkbox1) {
      //   ret = false
      // } else if (!_.isEmpty(this.lv_ChgData) && (!this.selectItems || !this.selectItems.vuchId)) {
      //   ret = false
      // }
      // 둘중 하나라도 선택 되면 넘어갈수 있는 로직

      // this.hasC 가 TRUE 여도 , this.lv_ChgData 데이터가 없는 경우가 있음
      // this.lv_selectItem 가 변경청약신청서발행 하단 목록의 음영
      // this.selectItems 가 변경청약신청서발행 하단 목록의 체크박스

      // ret = (this.pItem.contStatCdNm !== '작성중') && ((this.hasS && this.checkbox1 && !this.checkbox2 ) || (this.hasC && (this.selectItems && this.selectItems.vuchId)))
      
      let spcCndtlElstSno = this.lv_selectItem.spcCndtlElstSno

      if ( this.pItem.contStatCdNm !== '작성중' && this.hasS && this.realC && this.checkbox1 && this.checkbox2 && this.selectItems && this.selectItems.spcCndtlElstSno && spcCndtlElstSno !== undefined ) return true
      if ( this.pItem.contStatCdNm !== '작성중' && this.hasS && !this.realC && this.checkbox1 && !this.checkbox2 ) return true
      if ( this.pItem.contStatCdNm !== '작성중' && !this.hasS && this.realC && !this.checkbox1 && this.checkbox2 && this.selectItems && this.selectItems.spcCndtlElstSno && spcCndtlElstSno !== undefined) return true
      return ret
    },
    // lastElstSno () {
    //   return this.$bizUtil.lpad(this.lastChgObj.spcCndtlElstSno, 5, '0') + this.$bizUtil.lpad(this.lastSpcObj.spcCndtlElstSno, 5, '0')
    // },
    // selectedElstSno () {
    //   let chgSno = (!_.isEmpty(this.lastChgObj) && !this.checkbox2) || _.isEmpty(this.lastChgObj) || (this.checkbox2 && _.isEmpty(this.selectItems)) ? '00000' : this.$bizUtil.lpad(this.selectItems.spcCndtlElstSno, 5, '0')
    //   let spcSno = (!_.isEmpty(this.lastSpcObj) && !this.checkbox1) || _.isEmpty(this.lastSpcObj) ? '00000' : this.$bizUtil.lpad(this.lastSpcObj.spcCndtlElstSno, 5, '0')
    //   return chgSno + spcSno
    // },
    hasS () {
      let pItem = this.pItem
      let latestSendObj = this.latestSendObj
      return pItem && pItem.pSSpcObj && (pItem.pSSpcObj.vuchId || pItem.pSSpcObj.crncyCd) && ( pItem.pSSpcObj.mobpsUrlDspchYn === 'N' || (pItem.pSSpcObj.mobpsUrlDspchYn === 'Y' && Number(pItem.pSSpcObj.mobpsUrlDspchTrtDtm) > Number(latestSendObj.mobpsUrlDspchTrtDtm)))
    },
    hasC () {
      return (this.pItem.pSChgLst.length !== 0)
    },
    realC () {
      for (let i = 0; i < this.lv_ChgData.length; i++) {
        if (this.lv_ChgData[i].spcCndtlElstSno > this.lv_LastUseSno) {
          return true
        }
      }
      return false
    },
    lv_ChgData () {
      let lv_Vm = this
      let pItem = this.pItem
      if (!pItem.hasC) return [] // c없으면 빈값
      let latestSendObj = pItem.lastSendObj
      if (!latestSendObj.mobpsUrlDspchTrtDtm) latestSendObj.mobpsUrlDspchTrtDtm = 0
      if (pItem.contStatCdNm === '작성중') {
        if (latestSendObj.mobpsUrlDspchTrtDtm === pItem.lastSendObjC.mobpsUrlDspchTrtDtm) {
          // 마지막 보낸 시간이 일치할때만
          return [pItem.lastSendObjC]
        } else {
          return []
        }
      }
      let ret = lv_Vm.lv_Lst.filter(a => (Number(String(a.erdat) + String(a.erzet)) > Number(latestSendObj.mobpsUrlDspchTrtDtm)) && Number(a.erdat) >= Number(pItem.bef5Days))
      return ret
    },
    lv_SpcData () {
      let pItem = this.pItem
      if (!pItem.hasS) return {} // c없으면 빈값
      let latestSendObj = pItem.lastSendObj
      if (pItem.contStatCdNm === '작성중' && latestSendObj.mobpsUrlDspchTrtDtm === pItem.pSSpcObj.mobpsUrlDspchTrtDtm) {
        // 작성중일때 마지막으로 보낸 객체와 시간이 같을때만 출력
        return pItem.pSSpcObj
      } else if (pItem.contStatCdNm === '작성대상') {
        if (!latestSendObj.mobpsUrlDspchTrtDtm) latestSendObj.mobpsUrlDspchTrtDtm = 0
        if (Number(String(pItem.pSSpcObj.erdat) + String(pItem.pSSpcObj.erzet)) > Number(latestSendObj.mobpsUrlDspchTrtDtm)) {
          // 마지막으로 발송된 객체보다 생성된 시간이 뒤일때만 출력
          if (Number(pItem.pSSpcObj.erdat) >= Number(pItem.bef5Days)) {
            return pItem.pSSpcObj
          }
        }
      }
      return {}
    }
  },//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {

  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/
    /************************************************************************************************
     * Function명  : fn_OnLoad
     * 설명        : 화면초기화
     ************************************************************************************************/
    fn_OnLoad () {
      console.log('pItem=>', this.pItem)
      this.fn_SetLastChgData()
      if (this.pItem && this.pItem.pSChgLst) {
        this.fn_SetChgData()
      }
    },

    /************************************************************************************************
     * Function명  : fn_ClickPreview
     * 설명        : 사랑On에서 WebView로 호출
     ************************************************************************************************/
    fn_ClickPreview: function (serviceName, item) {
      if (_.isEmpty(item)) {
        this.rowPopupData = this.pItem.pSSpcObj
      } else {
        this.rowPopupData = item
      }
      this.fn_ServiceData(serviceName)
    },

    /************************************************************************************************
     * Function명  : fn_OpenWebViewURL
     * 설명        : 사랑On에서 WebView로 호출
     ************************************************************************************************/
    fn_OpenWebViewURL: function (pURL) {
      let serverChoice = process.env.NODE_ENV
      let targetUrl = pURL

      if (this.$commonUtil.isMobile()) {
        window.fdpbridge.exec('PhoneWebViewPlugin', {uri: targetUrl}, () => {}, () => {})
      } else {
        // 운영환경이 아닌 경우 get 방식으로 파라미터 호출
        if (serverChoice !== 'production') {
          window.open(targetUrl, '_blank')
        }
      }
    },
    /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler() {
      return
    }, //fn_DevicBackBtnHandler

    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : 샘플 서비스 호출 함수
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      this.$router.go(-1)      
    },// fn_HeaderBackBtnHandler


    /******************************************************************************
    * Function명 : fn_Reset
    * 설명       : 초기화
    ******************************************************************************/
    fn_Reset: function () {
      this.stndKey = ''        
      this.rsltItemList.splice(0, this.rsltItemList.length) //바인딩 이벤트 강제 발생 splice 로 삭제 처리
      this.slctItamData = null
    },

    /******************************************************************************
    * Function명  : fn_ListItemRadioSlctHandler
    * 설명        : 리스트 아이템 로우 라디오 선택 핸들러
    ******************************************************************************/
    fn_ListItemRadioSlctHandler(slctItem) {  
      this.selectItems.vuchId = slctItem.vuchId
      this.selectItems.spcCndtlElstSno = slctItem.spcCndtlElstSno

      this.lv_selectItem = slctItem
    },


  /*---------------------------------------------------------------------------------
  * Data Service 핸들러 영역
  ---------------------------------------------------------------------------------*/
    /******************************************************************************
    * Function명 : fn_SetLastChgData
    * 설명       : 마지막 발송 데이터 세팅
    *            :
    ******************************************************************************/
    fn_SetLastChgData () {
      let item = this.pItem
      let pSChgLst = item.pSChgLst
      let pSSpcObj = item.pSSpcObj.mobpsUrlDspchYn === 'Y' ? item.pSSpcObj : {}
      let chgObj = {}
      console.log('fn_SetDetail', item)
      if (!_.isEmpty(pSChgLst)) {
        console.log('pSChgLst not empty start reduce')
        let tLst = pSChgLst.filter(a => a.mobpsUrlDspchYn === 'Y')
        tLst = tLst.slice(0, tLst.length)
        chgObj = _.isEmpty(tLst) ? {} : tLst.reduce((a, b) => Number(a.aedat + a.aezet) > Number(b.aedat + b.aezet) ? a : b )
      }
      if (!_.isEmpty(pSChgLst)) {
        console.log('reduce finished well chgObj-->', chgObj)
      }
      this.lastChgObj = chgObj
      this.lastSpcObj = pSSpcObj
      if (!_.isEmpty(this.lastSpcObj) && !_.isEmpty(this.lastChgObj)) {
        this.latestSendObj = Number(this.lastSpcObj.mobpsUrlDspchTrtDtm) > Number(this.lastChgObj.mobpsUrlDspchTrtDtm) ? this.lastSpcObj : this.lastChgObj
      } else if (!_.isEmpty(this.lastSpcObj)) {
        // spc Obj 있을때
        this.latestSendObj = this.lastSpcObj
      } else if (!_.isEmpty(this.lastChgObj)) {
        // chg Obj 있을때
        this.latestSendObj = this.lastChgObj
      } else {
        this.latestSendObj.mobpsUrlDspchTrtDtm = 0
      }
    },

    /******************************************************************************
    * Function명 : fn_SetChgData
    * 설명       : 변경청약 리스트 계약금액 세팅
    *            :
    ******************************************************************************/
    fn_SetChgData () {
      console.log('fn_SetChgData')
      let lv_Vm = this
      let pParams = {
        lst: lv_Vm.pItem.pSChgLst
      }
      lv_Vm.lv_Lst = lv_Vm.pItem.pSChgLst.slice(0, lv_Vm.pItem.pSChgLst.length)
      const trnstId = 'txTSSPS90S9'
      const auth = 'S'
      lv_Vm.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          let lst = response.body.lst
          console.log('fn_SetChgData response ==>', response)
          for (let i = 0; i < lst.length; i++) {
            let json = JSON.parse(lst[i].contPrm)
            lv_Vm.lv_Lst[i].contPrm = json['DATA_ARY'][0]['SingleValueMap']['Afc_ToT_Prm']
          }
          let tLst = lv_Vm.lv_Lst
          for (let i = 0; i < tLst.length; i++) {
            lv_Vm.fn_SetDetail(tLst[i])
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명  : fn_ServiceData
    * 설명        : 서비스 호출 함수 정의
    ******************************************************************************/
    fn_ServiceData: function (serviceName) {
      let pParams = {}
      //this.isLoadingStatus = true
      let tParams = {}
      switch (serviceName) {
        case 'S1': // 특별조건부/변경청약 암호화된 URL
          tParams.appId = 'tss.ps'
          tParams.trnstId = 'txTSSPS90S1'
          tParams.fnctScCd = 'S'
          tParams.serviceId = ''
          tParams.reqSrvcNm = 'IPSSpcChgSVCImpl'
          tParams.reqMthdNm = 'selURLData'
          tParams.reqVONm = 'sli.tss.ps.eltrnsign.svc.svo.PSSpcChgSVO'
          tParams.resVONm = 'sli.tss.ps.eltrnsign.svc.svo.PSSpcChgSVO'
          tParams.srnId = '' // 대표 화면 명
          tParams.data = {}
          tParams.data.vuchId = '' // 전자문서ID

          pParams = Object.assign({}, tParams)
          pParams.data = {}
          // reqVO.setVuchId(tTSD02002DVO.getVuchId());  // 영수증번호
          // reqVO.setContPtcpScCd(tTSD02002DVO.getContPtcpScCd()); // 계약관계인 구분코드 1: 계약자 9 FC등
          // reqVO.setSpcCndtlElstSno(tTSD02002DVO.getSpcCndtlElstSno()); // 특별조건부 전자서명일련번호
          // reqVO.setMobslElstScCd(tTSD02002DVO.getMobslElstScCd()); // 모바일영업전자서명구분코드 A C S 등
          // 빈값검증 추가.
          pParams.data.vuchId = this.rowPopupData.vuchId // 영수증ID
          pParams.data.contPtcpScCd = '1' // 일단 1
          pParams.data.spcCndtlElstSno = this.rowPopupData.spcCndtlElstSno
          pParams.data.mobslElstScCd = this.rowPopupData.mobslElstScCd
          break
        default:
          break
      } // end switch
            
      pParams.srnId = this.$options.screenId // 호출 화면 명
      PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)
      
    },

   /************************************************************************************************
     * Function명  : fn_ServiceResultHandler
     * 설명        : 서비스 호출 결과 함수 정의
     ************************************************************************************************/
    fn_ServiceResultHandler(event, serviceName) {
      let lv_data = event.data

      switch (serviceName) {
        case 'S1': // 모바일청약 중단건진행현황조회
          console.log('openViewURL->', lv_data.urlData)
          this.fn_OpenWebViewURL(lv_data.urlData)
          break
        default:
          break
      } // switch (serviceName)
      
    },

    /************************************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 에러 이벤트
     ************************************************************************************************/
    fn_ServiceFaultHandler: function (event, serviceName) {
      this.getStore('confirm').dispatch('ADD', event.msgDesc)
    },

    /************************************************************************************************
     * Function명  : fn_TableStat
     * 설명        : 데이터 없는 페이지 설정
     * 
     ************************************************************************************************/
    fn_TableStat() {
      this.isNodata = true
      //this.rsltItemList = []
      //Object.assign(this._vm.stndKeyList[0], {stndKeyId: 'next_key', stndKeyVal: '0'} )
      this.rsltItemList.splice(0, this.rsltItemList.length) //바인딩 이벤트 강제 발생 splice 로 삭제 처리
      this.slctItamData = null
    },

    /******************************************************************************
    * Function명  : fn_Time
    * 설명        : 시간 포멧
    ******************************************************************************/
    fn_Time (str) {
      if (str) {
        return str.slice(0, 2) + ':' + str.slice(2, 4) + ':' + str.slice(4, 6)
      }
    },

    /******************************************************************************
     * Function명 : fn_Confirm
     * 설명       : Confirm 팝업 호출 - FC 주의 문구 노출
     ******************************************************************************/
    fn_Confirm () {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title: '알림',
          content: '<span class="crTy-orange2">[미리보기]</span>를 통해 미결보완 필요내용이 맞는지 <span class="crTy-orange2">반드시 확인 후</span> 발송 부탁드립니다.',
          title_pos_btn: "확인",
          title_neg_btn: "취소"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(this.lv_AlertPop);
            this.fn_Next()
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.lv_AlertPop);
          }
        }
      })
    },

    /************************************************************************************************
     * Function명  : fn_Next
     * 설명        : 미결보완 발송화면으로 이동
     ************************************************************************************************/
    fn_Next () {
      let nextObj = {}
      if (this.checkbox1) {
        nextObj.spcChgDefCd = '2'
        nextObj.spcObj = this.pItem.pSSpcObj
        nextObj.spcObj.vuchId = !_.isEmpty(nextObj.spcObj.vuchId) ? nextObj.spcObj.vuchId : nextObj.spcObj.planId
      }
      if (this.checkbox2) {
        nextObj.spcChgDefCd = '1'
        nextObj.cngObj = this.lv_selectItem
        nextObj.cngObj.vuchId = !_.isEmpty(nextObj.cngObj.vuchId) ? nextObj.cngObj.vuchId : nextObj.cngObj.planId
      }
      if (this.checkbox1 && this.checkbox2) {
        nextObj.spcChgDefCd = '3'
      }
      if ((this.checkbox1 && !_.isEmpty(nextObj.spcObj)) || (this.checkbox2 && !_.isEmpty(nextObj.cngObj))) {
        this.$emit('nextBtnClicked', nextObj)
      }
    },

    /******************************************************************************
    * Function명  : fn_SetDetail
    * 설명        : 데이터 상세정보
    ******************************************************************************/
    fn_SetDetail (pItem) {
      console.log(pItem)
      if (pItem.mobslElstScCd === 'S' || pItem.mobslElstScCd === 'C') {
      // 1_1, 1_2 특별조건 요청이 있고 모바일 전송을 하지 않은 경우.
        if (pItem.mobpsUrlDspchYn === 'N') {
          // 전송 안한 경우
          pItem.contStatCdNm = '작성대상'
        } else {
          // 전송한경우
          if (pItem.mobpsUrlDspchYn === 'Y') {
            this.lv_LastUseSno = Number(pItem.spcCndtlElstSno) > this.lv_LastUseSno ? Number(pItem.spcCndtlElstSno) : this.lv_LastUseSno
          }
          // 1_3 전송했다면 해당 영수증번호의 마지막 변경청약 모바일전송시간과 가장 최근의 특별조건부 or 변경청약 요청시간을 비교, 가장 최근의 변경청약 요청시간이 마지막 모바일전송시간 이후인 경우
          if (Number(String(pItem.aedat) + String(pItem.aezet)) > Number(pItem.mobpsUrlDspchTrtDtm)) { // 마지막 변경 시간 > 마지막 변청 모바일 전송시간.
            pItem.contStatCdNm = '작성대상'
          }
        }
        // 2_1 신계약 진행상태가 미결 이고(spcCndtlObjYn === 'Y') 해당 영수증번호로 특별조건부 요청이 있고( pItem.mobslElstScCd === 'S' ), 모바일 전송도 되었으나 (mobpsUrlDspchYn === 'Y'). -> ( 가장 최근건이 미완료(pItem.elstPrgStatCd !== '50') -> 작성중)
        // 2_2 해당 영수증번호로 변경청약( pItem.mobslElstScCd === 'S') 모바일 전송이 되었고(mobpsUrlDspchYn === 'Y') 완료건이 없는 경우(pItem.elstPrgStatCd !== '50').
        if (pItem.insrdElstYn === 'Y' && pItem.contrElstYn === 'Y') {
          // 2_3 특별조건부 or 변경청약(pItem.mobslElstScCd === 'S' || pItem.mobslElstScCd === 'C') 완료건이 있는경우(pItem.elstPrgStatCd === '50') { 작성완료 노출
          pItem.contStatCdNm = '작성완료'
          if (pItem.spcCndtlObjYn === 'Y') {
            pItem.prgPhseNm = '재처리' // 작성완료(pItem.elstPrgStatCd === '50')인데, 재처리가 필요한 경우(pItem.spcCndtlObjYn === 'Y') [진행상태] 대신 [재처리]를 표시  // 10:서명중/20:서명완료/30:접수완료/40:이체완료
          } else {
            pItem.prgPhseNm = '진행상태'
          }
        } else {
          pItem.contStatCdNm = '-'
        }
      }
    },

    
    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>