/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS260M
 * 화면 설명: 설계청약 현황 > 미결진행
 */
<template>
  <ur-page-container class="msp" type="subpage" title="미결진행" :show-title="true" :action-type="actionType" :topButton="true"
    @on-scroll-bottom="fn_ScrollBottomHandler"
    @on-header-left-click="fn_HeaderBackBtnHandler">
    <!-- Content영역 -->
    
    <template #frame-header-fixed>
      <ur-box-container direction="column" alignV="start" class="bgcolor-1 pa2024"> 
        <ul class="bullet-type-3 crTy-bk7 fs14rem">
          <li>미결보완 서류 목록은 지점프로를 통해 최종 전송된 기준입니다.</li>
        </ul>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title align-item-center">
              <span class="sum">총<span class="count">{{rsltCnt}}</span>건</span>
              <!-- 리스트 정렬 컴포넌트 -->
              <PSListSortOpt ref='sortFieldComp' defaultLabel='정렬선택' :sortFieldList='sortFieldList' @sortChange='fn_SortChangeHandler' @sortReset='fn_SortResetHandler'/>
              <!--/ 리스트 정렬 컴포넌트 -->  
            </div>
          </div>
        </mo-list-item> 
      </ur-box-container>
    </template>
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">      
      <ur-box-container v-if="!isNodata" direction="column" alignV="start" class="">
        <ur-box-container alignV="start" componentid="" direction="column" class="status-list type-expand">
          <ur-box-container alignV="start" componentid="" direction="column" class="" style="border-bottom: 0 none;">      
            <mo-list :list-data="rsltItemList">
              <template #list-item="{item, index}">      
                <mo-list-item class=""> 
                  <msp-expand btn-area-first title-first expandable :preventScrollWhenExpand="true" :ref="'expItem_'+index" expand-only-btn class="mo-list-expand w100" btn-area-class="fexTy3 firstdiv-full mb0"> 
                    <template #title>
                      <div class="list-item__contents">
                        <div class="list-item__contents__title pt6">
                          <span class="name txtSkip fs19rem fwb">{{item.prdtNm}}</span>
                        </div>
                        <div class="mt10 mb4">
                          <span class="fs14rem mr6 crTy-bk7">피보험자</span> <span class="crTy-bk1 fs16rem txtSkip--psjjh maxW70 min70 underline" @click.stop="fn_PopupCustNmUiCard(item.insrdChnlCustId)">{{item.insrdNm}}</span>
                          <em class="em_normal">|</em>
                          <span class="fs14rem mr8 crTy-bk7">청약일</span><span class="crTy-bk1 fs16rem">{{item.contYmdLabel}}</span>
                        </div>
                        <div class="mb20">
                          <span class="fs14rem mr6 crTy-bk7">보험료</span> <span class="crTy-blue3 fs16rem fwb">{{$bizUtil.numberWithCommasCurr(item.smtotPrm, item.zaCrncyCd, 'Y', 'Y', 3)}}</span>
                        </div>
                      </div>
                    </template>
                    <template #btn>
                      <mo-button class="link-arrow down mb76"></mo-button>
                    </template>
                    <template #content>
                      <div class="list-item-detail gray_style bdnone margin--full pt20 pb20">
                        <ur-box-container class="con--padding ">
                          <div class="contents-row">
                              <span class="dsInline crTy-bk7 fs14rem mr20 min100">계약자</span><span class="fs16rem underline" @click.stop="fn_PopupCustNmUiCard(item.contrChnlCustId)">{{item.custNm}}</span>
                          </div>
                          <div class="contents-row mt4">
                              <span class="dsInline crTy-bk7 fs14rem mr20 min100">영수증번호</span><span class="fs16rem">{{item.planId}}</span>
                          </div>
                        </ur-box-container>
                      </div>
                    </template>
                  </msp-expand>
                </mo-list-item>
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box expand_bottomline">
                  <div class="ns-btn-relative-area mt0">
                    <div class="ns-btn-relative-s">
                      <div class="relative-div mb5 bn-nowrap">
                        <mo-button v-if="item.contStatCdNm === '작성완료'" componentid="" class="ns-btn-round white ml0 mr0" :disabled="item.contStatCdNm === '작성완료'">{{item.contStatCdNm}}</mo-button>
                        <mo-button v-if="item.contStatCdNm === '작성대상' || item.contStatCdNm === '작성중'" class="ns-btn-round white ml0 mr0" :disabled="item.contStatCdNm === '작성완료'" @click="fn_WrtObjOpenPopup(item)">{{item.contStatCdNm}}</mo-button>
                        <mo-button v-if="item.prgPhseNm === '재처리'" componentid="" class="ns-btn-round white ml0 mr0" @click="fn_PrgPhseOpenPopup(item)">{{item.prgPhseNm}}</mo-button>
                        <mo-button v-else-if="item.prgPhseNm === '진행상태'" componentid="" class="ns-btn-round white ml0 mr0" @click="fn_PrgStatOpenPopup(item)">{{item.prgPhseNm}}</mo-button>
                        <template v-if="!item.isOnlyApnd">
                          <mo-button v-if="item.contStatCdNm === '작성완료' && item.urlMoblReciCnsntYn === 'Y'" componentid="" class="ns-btn-round white ml0 mr0" @click="fn_PrcsCntptKaKaoIapSend(item)">알림톡발송</mo-button>
                          <mo-button v-else-if="item.contStatCdNm === '작성완료'" componentid="" class="ns-btn-round white ml0 mr0" @click="fn_SpcCndtlPrntOpenPopup(item)">지점인쇄</mo-button>
                        </template>
                      </div>
                    </div>
                  </div>
                </ur-box-container>
              </template>
            </mo-list>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>

      <!-- NoData 영역 ur-box-containe  -->        
      <PSNodataMesgBoxForList v-else ref="nodataMesgBox"/>        
      <!-- / NoData 영역 ur-box-containe -->
    </ur-box-container>
    <!-- content 영역 -->
  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import PSConstants from '@/config/constants/psConstants'
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager'
import PSHeaderSubMenuPopup from '@/ui/ps/comp/PSHeaderSubMenuPopup'
import PSListSortOpt from '@/ui/ps/comp/PSListSortOpt'
import PSNodataMesgBoxForList from '@/ui/ps/comp/PSNodataMesgBoxForList'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import MSPPS201P from '@/ui/ps/MSPPS201P' //검색 키워드 입력 팝업
// 특별조건부 / 변경청약
import MSPPS261P from '@/ui/ps/MSPPS261P' //특별조건부 전자서명 완료 재처리 (미결진행 재처리)
import MSPPS262P from '@/ui/ps/MSPPS262P' //미결보완 작성대상 팝업
import MSPPS263P from '@/ui/ps/MSPPS263P' //미결보완 고객 진행상태
// 추가병력고지
import MSPPS266P from '@/ui/ps/MSPPS266P' //병력미결 진행 재처리 (미결진행 재처리)
import MSPPS265P from '@/ui/ps/MSPPS265P' //병력미결 고객 진행상태
import MSPPS264P from '@/ui/ps/MSPPS264P' //병력미결 작성대상 및 미리보기

export default {

/***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS260M', 
  screenId: 'MSPPS260M',

  components: {
    'PSHeaderSubMenuPopup' : PSHeaderSubMenuPopup, // 서브메뉴 팝업
    'PSNodataMesgBoxForList' : PSNodataMesgBoxForList, //데이터없음 메세지 출력 박스
    'PSListSortOpt' : PSListSortOpt, //리스트 정렬
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {            
      actionType : 'none',
      bef5Days: '', //-5영업일자
      
      stndKey: '', // 페이징 Next Key
      isNodata: false, // 데이터 없음 Flag            
      slctItamData : null, // 선택된 item Row Object Data  
      rsltCnt : 0 ,  // 조회결과 누적 데이터 길이
      rsltItemList : [], // 조회결과 리스트 데이터
      orgItems: [], // 전체 리스트 목록 복사본
      rtnSearchPopupData: {searchScVal : '', searchKeyword : ''}, // 검색팝업 리턴값
      
      sortFieldList : []
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {    
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

    this.actionType = PSCommUtil.fn_SetAppBarSearchMoreMenu(this.fn_HeaderSerchHander, this.fn_MoreMenuHandler)
    this.fn_SetOrderByFields()  //정렬 컴포넌트 필드정의 셋팅

    this.fn_CalcBef5Days()
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { return this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {

  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/


    /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler() {
       if(PSCommUtil.fn_IsActiveModal()) return
      this.fn_HeaderBackBtnHandler()
    }, //fn_DevicBackBtnHandler

    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : 샘플 서비스 호출 함수
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      // 홈으로 이동
      this.$router.push({ name: 'MSPBC002M' })
    },// fn_HeaderBackBtnHandler


    /******************************************************************************
    * Function명 : fn_Reset
    * 설명       : 초기화
    ******************************************************************************/
    fn_Reset: function () {
      this.fn_SortResetHandler()  // 정렬 리셋
      
      this.searchKeyword = ''
      this.stndKey = ''        
      this.rsltItemList.splice(0, this.rsltItemList.length) //바인딩 이벤트 강제 발생 splice 로 삭제 처리
      this.slctItamData = null
    },

    /******************************************************************************
    * Function명  : fn_HeaderSerchHander
    * 설명        : Header 검색버튼 핸들러
    ******************************************************************************/
    fn_HeaderSerchHander() {
      let lv_Vm = this

      const searchPopup = this.$bottomModal.open(MSPPS201P, {
        properties: {
          pParntSrnId: lv_Vm.$options.screenId, // 부모 화면 ID
          pSearchKeyword: lv_Vm.rtnSearchPopupData.searchKeyword, //Default set Keyword Value
          pSearchScVal: lv_Vm.rtnSearchPopupData.searchScVal
        },
        
        listeners: {
          //대상팝업 onPopupReset $emit 이벤트명 backkey reset or reset
          onPopupReset : () => {
            lv_Vm.fn_Reset()
            lv_Vm.fn_ServiceData('S')
            lv_Vm.$bottomModal.close(searchPopup) // 모달 닫기                 
          },
          // 대상팝업 onPopupSearch $emit 이벤트명 
          onPopupSearch: (rtnData) => {
            console.log(rtnData)

            lv_Vm.rtnSearchPopupData.searchScVal = rtnData.searchScVal // 검색구분값
            lv_Vm.rtnSearchPopupData.searchKeyword = rtnData.searchKeyword // 검색키워드

            if (rtnData.searchKeyword !== '') {   //필터검색
              lv_Vm.fn_FltSearch(rtnData)
            }
            else {
              lv_Vm.fn_Reset()
              lv_Vm.fn_ServiceData('S')
            }
            
            lv_Vm.$bottomModal.close(searchPopup) // 모달 닫기                        
          }          
        }
      })
      
    },// fn_HeaderSerchHander

    /******************************************************************************
    * Function명  : fn_ScrollBottomHandler
    * 설명        : Scroll Bottom 핸들러
    ******************************************************************************/
    fn_ScrollBottomHandler() {
       this.fn_ServiceData('N')
    },// fn_ScrollBottomHandler

    fn_MoreMenuHandler(){
      console.log('fn_MoreMenuHandler!!!')
      //팝업 열기 
      let subMenuModalId = this.$bottomModal.open(PSHeaderSubMenuPopup, {
        properties: {          
          parentSrnId  : this.$options.screenId, // 부모 화면 ID
          popupTitle   : '청약설계 업무 더보기',                    
          subMenuList  : PSCommUtil.fn_GetOfrPlanMoreMenuList(), //청약설계 보조메뉴 리스트
          closeBtn     : true
        },
        listeners: {
          popupEventClose : () => {
            this.$bottomModal.close(subMenuModalId) // 모달 닫기
          },
          popupEventRouterMove: (rtnData) => {//subMenuList에서 선택함 Item 리턴
            this.$bottomModal.close(subMenuModalId) // 모달 닫기
            this.isActivePopup = false
          },      
        }
      },{properties: {closeBtn: true}})
    },

    /******************************************************************************
    * Function명  : fn_SetOrderByFields
    * 설명        : 정렬 컴포넌트 필드정의 셋팅
    *               화면에서 소트 컨트롤 방식 셋팅 fn_SortChangeHandler 에서 처리
    * 
    ******************************************************************************/
    fn_SetOrderByFields () {
        this.sortFieldList = [
           {field : 'contYmd' ,    label : '청약일'   , sort: 'DESC'  }
          ,{field : 'insrdNm' ,    label : '피보험자'    , sort: 'ASC' }
          ,{field : 'custNm' ,  label : '계약자'    , sort: 'ASC' }
        ]
    },

    /******************************************************************************
    * Function명  : fn_SortChangeHandler
    * 설명        : 정렬 이벤트 핸들러
    *               pSortOrder = {field : 'title' , label : '제목' , sort: 'ASC' or 'DESC'}
    ******************************************************************************/
    fn_SortChangeHandler (pSortOrder){
      console.log('fn_SortChangeHandler !!!')
      
      let sortField = pSortOrder.field
      let sortOrderby = pSortOrder.sort //ASC or DESC
      
      console.log(pSortOrder)
      console.log('sortField = '+sortField+ ' , sortOrderby = '+sortOrderby)

      switch(sortField){
        case 'custNm':  //String 형 sort
        case 'insrdNm':  //String 형 sort
          PSCommUtil.fn_SortListValueString(sortOrderby, this.rsltItemList, sortField)
          break
        case 'contYmd': // 날짜형 String 을 Date 형으로 변환 처리 해서 sort
          PSCommUtil.fn_SortListValueDateString(sortOrderby, this.rsltItemList, sortField, 'yyyy-MM-dd') //yyyy-MM-dd
          break
      }//switch

    },

    /******************************************************************************
    * Function명  : fn_SortResetHandler
    * 설명        : 정렬 리셋 이벤트 핸들러
    ******************************************************************************/
    fn_SortResetHandler(){
      console.log('fn_SortReset !!!')
      //원본으로 되돌리기
      // this.dataList = []      
      // this.orgDataList.forEach(item => {
      //   this.dataList.push(Object.assign({},item))
      // });

      this.$refs.sortFieldComp.fn_Reset()
    },


  /*---------------------------------------------------------------------------------
  * Data Service 핸들러 영역
  ---------------------------------------------------------------------------------*/
    
    /******************************************************************************
    * Function명  : fn_ServiceData
    * 설명        : 서비스 호출 함수 정의
    ******************************************************************************/
    fn_ServiceData(serviceName) {
     
     // 더보기 마지막 조회때 stndKey = '' 으로 되기때문에 마지막 검색으로 판단하고 추가 조회 안함
     if (serviceName === 'N' && this.stndKey === '') return

      let pParams = {}
      switch (serviceName) {
        case 'S': // 미결진행 조회
          window.vue.getStore('progress').dispatch('SKEL_LIST')
        case 'N': // 미결진행 더보기
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS31S1'))
          pParams.srnId = this.$options.screenId // 대표 화면 명
          pParams.data.zNcontPrgPnstaIfDto.clctCnsltNo = PSServiceManager.getUserInfo('userId') // FC 사번
          
          // 페이징 처리
          pParams.data.zNcontPrgPnstaIfDto.pagingDto.fnctScCd = serviceName
          pParams.data.zNcontPrgPnstaIfDto.pagingDto.stndKey = (serviceName === 'S') ? '' : this.stndKey
          break
        case 'S5': // 특별조건부 신청서 출력
          console.log(this.slctItamData)
          if (this.slctItamData.mobslElstScCd === 'S' || this.slctItamData.mobslElstScCd === 'C') {
            pParams = {}
            pParams.appId = 'sli.fom'
            pParams.trnstId = 'txTSSPS31T21'
            // pParams.fnctScCd = 'T'
            pParams.serviceId = ''
            // pParams.reqSrvcNm = 'DCCndtlRTransCtlImpl'
            pParams.reqSrvcNm = 'DCChgEtapSignCtlImpl'
            pParams.reqMthdNm = 'prtEltrnSign'
            pParams.reqVONm = 'sli.mo.vo.dc.eltrnsign.DCPrtDocuVO'
            pParams.resVONm = 'sli.mo.vo.dc.eltrnsign.DCPrtDocuVO'
            // pParams = Object.assign({}, ServiceManager.getParameter('txTSSPS29T1')) // 계약서류 출력
            pParams.data = {}
            // pParams.srnId = 'TSSPS316D' // 대표 화면 명
            // pParams.data.eltrnDoctId = this.rowPopupData['vuchId'] // 전자문서ID
            pParams.data.eltrnDoctId = this.slctItamData['docIdSpcChg'] // 전자문서ID(영수증번호+특별조건부일련번호)
          } else if (this.slctItamData.mobslElstScCd === 'Y') {
            pParams = {}
            pParams.appId = 'sli.fom'
            pParams.trnstId = 'txTSSPS32T1'
            pParams.serviceId = ''
            pParams.reqSrvcNm = 'DCAddmAnmsSignCtlImpl'
            pParams.reqMthdNm = 'prtEltrnSign'
            pParams.reqVONm = 'sli.mo.vo.dc.eltrnsign.DCPrtDocuVO'
            pParams.resVONm = 'sli.mo.vo.dc.eltrnsign.DCPrtDocuVO'
            pParams.data = {}
            pParams.data.eltrnDoctId = this.slctItamData['docIdAnms'] // 전자문서ID(영수증번호+특별조건부일련번호)
          }
          pParams.data.aplrEno = PSServiceManager.getUserInfo('userId') // 신청자사번
          pParams.data.aplrEnm = PSServiceManager.getUserInfo('userNm') // 신청자명(설계사명)
          pParams.data.prdtNm = this.slctItamData['mnPrdtNm'] // 주상품명
          break
        default:
          break
      } // end switch
      PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)
    },

   /************************************************************************************************
     * Function명  : fn_ServiceResultHandler
     * 설명        : 서비스 호출 결과 함수 정의
     ************************************************************************************************/
    fn_ServiceResultHandler(event, serviceName) {
      let lv_data = event.data
      let lv_Vm = this
      let stndKeyList = event.trnstComm.stndKeyList
      switch (serviceName) {
        case 'S': // 신계약진행 조회
          window.vue.getStore('progress').dispatch('PART')
        case 'N': // 신계약진행 더보기
          this.stndKey = lv_data.zNcontPrgPnstaIfDto.pagingDto.stndKey
          let t_rslt
          if (lv_data.zNcontPrgPnstaIfDto && lv_data.zNcontPrgPnstaIfDto.contPrgStatListVO) {
            t_rslt = lv_data.zNcontPrgPnstaIfDto.contPrgStatListVO
          }
          // 특별조건부 전자서명 정보
          let t_spcCndtlElst
          if (lv_data.pSSpcCndtlElstInfoSVO && lv_data.pSSpcCndtlElstInfoSVO) {
            t_spcCndtlElst = lv_data.pSSpcCndtlElstInfoSVO
          }
          // 변경청약 목록
          let t_PSChgLstSVO = []
          if (lv_data.pSChgLst) {
            t_PSChgLstSVO = lv_data.pSChgLst
          }

          // 특별조건부 객체
          let t_PSSpcObj = {}
          if (lv_data.pSSpcObj) {
            t_PSSpcObj = lv_data.pSSpcObj
          }

          // 병력미결 객체
          let t_PSAnmsObj = {}
          if (lv_data.pSAnmsObj) {
            t_PSAnmsObj = lv_data.pSAnmsObj
          }

          let t_rowIndex = this.rsltItemList.length
          let idx = 0
          if (t_rslt && t_rslt.length > 0) {
            t_rslt.forEach(pItem => {
              pItem.id = t_rowIndex++
              pItem.prdtNm = pItem.prdtNm.replace('삼성생명', '').replace('삼성', '').trim() // 설계명에 삼성생명, 삼성 제거 처리
              pItem.contYmdLabel = pItem.contYmd.substring(5, 10) // 2018-10-19 00:00:00.0
              pItem.vuchId = t_spcCndtlElst[idx].vuchId
              pItem.spcCndtlElstSno = t_spcCndtlElst[idx].spcCndtlElstSno
              pItem.mnContrNm = t_spcCndtlElst[idx].mnContrNm
              pItem.mobslChnlCustId = t_spcCndtlElst[idx].mobslChnlCustId
              pItem.insrdChnlCustId = t_spcCndtlElst[idx].insrdChnlCustId
              pItem.mnPrdtNm = t_spcCndtlElst[idx].mnPrdtNm
              pItem.contPrm = t_spcCndtlElst[idx].contPrm
              pItem.isuMthScCd = t_spcCndtlElst[idx].isuMthScCd
              pItem.dfraWcnstSlctYn = t_spcCndtlElst[idx].dfraWcnstSlctYn
              pItem.contrJobpNm = t_spcCndtlElst[idx].contrJobpNm
              pItem.insrdJobpNm = t_spcCndtlElst[idx].insrdJobpNm
              pItem.elstPrgStatCd = t_spcCndtlElst[idx].elstPrgStatCd
              // pItem.doctId = t_spcCndtlElst[idx].spcCndtlElstSno ? t_spcCndtlElst[idx].vuchId + lv_Vm.$bizUtil.lpad(t_spcCndtlElst[idx].spcCndtlElstSno, 5, '0') : ' ' // fn_SetDetail에서 추가
              pItem.elst2PhseCmpltYn = t_spcCndtlElst[idx].elst2PhseCmpltYn
              pItem.elst4PhseCmpltYn = t_spcCndtlElst[idx].elst4PhseCmpltYn
              pItem.fullFilePath = ''
              // 20201119 모바일 청약 추가
              pItem.mobpsUrlDspchYn = t_spcCndtlElst[idx].mobpsUrlDspchYn
              pItem.mobpsUrlDspchTrtDtm = t_spcCndtlElst[idx].mobpsUrlDspchTrtDtm// 모바일 청약 URL 발송여부
              pItem.mobslElstScCd = t_spcCndtlElst[idx].mobslElstScCd // 모바일 청약 URL 발송 처리일시
              pItem.pSChgLst = t_PSChgLstSVO[idx]
              pItem.pSSpcObj = t_PSSpcObj[idx]
              pItem.elstPlanId = t_spcCndtlElst[idx].elstPlanId
              pItem.contStatCdNm = '-'
              pItem.prgPhseNm = '-'
              // [선심사PJT] 병력미결 추가
              pItem.pSAnmsObj = t_PSAnmsObj[idx]
              pItem.t_spcCndtlElst = t_spcCndtlElst[idx]
              pItem.contrEltrnMalReciYn = t_spcCndtlElst[idx].contrEltrnMalReciYn // 계약자전자메일수신여부
              pItem.urlMoblReciCnsntYn = t_spcCndtlElst[idx].urlMoblReciCnsntYn // URL모바일수신동의여부

              this.fn_SetDetail(pItem)
              this.rsltItemList.push(pItem)

              idx++
            })
            this.orgItems = this.rsltItemList

            //ody 펼침추가
            this.$nextTick( () => { // DOM의 상태가 변경 될 때 호출
              for(let i = 0 ; i < this.orgItems.length; i++){
                this.$refs['expItem_'+i].isExpand = true // 아코디언 펼침 처리
              }
            });
            //ody 펼침추가
          } else {
            if (serviceName === 'S') this.fn_TableStat() // 조회결과 없음 처리
          }
   
          this.rsltCnt = (this.rsltItemList)? this.rsltItemList.length : 0
          this.isNodata = (this.rsltCnt <= 0)? true : false

          // if(this.rsltCnt > 0 && serviceName === 'S'){
          //   this.$nextTick(()=>{
          //     PSCommUtil.fn_MoListItemExpandIdx(this.$refs.moList,0,true)
          //   })
          // }

          break
        
        case 'S5': // 계약서류 출력
          if (Number(lv_data.wkRslt) === 0) { // 작업결과 (1인경우 성공)
            this.getStore('toast').dispatch('ADD', this.$t('ps')['EPSC008']) // '계약서류가 인쇄되었습니다.')
          } else {
            this.getStore('toast').dispatch('ADD', lv_data.wkRsltMsg)
          } // end else if
          break
        default:
          break
      } // end switch
      
    },

    /******************************************************************************
    * Function명  : fn_SetDetail
    * 설명        : 데이터 상세정보
    ******************************************************************************/
    fn_SetDetail (pItem) {
      // 미결보완 요청 여부 YN
      if (pItem.spcCndtlObjYn === 'Y') {
        // 서류등록만 진행하는 경우(부본 지점인쇄/알림톡발송 불가): 특별조건부/변경청약은 false
        pItem.isOnlyApnd = false 

        if (_.isEmpty(pItem.vuchId)) {
          if (!_.isEmpty(pItem.planId)) {
            pItem.vuchId = pItem.planId
          }
        }
        let pSSpcObj = pItem.pSSpcObj // 마지막 조건부 Obj
        let hasS = true
        if (_.isEmpty(pSSpcObj.crncyCd)) { // 객체 비어있는지 확인.
          hasS = false
        }
        pItem.hasS = hasS
        if (!hasS) {
          pSSpcObj.mobpsUrlDspchYn = 'N'
          pSSpcObj.mobpsUrlDspchTrtDtm = 0
        }

        let pSChgLst = pItem.pSChgLst
        for (let i = 0; i < pSChgLst.length; i++) {
          if (pSChgLst[i].mobpsUrlDspchYn === 'N') {
            pSChgLst[i].mobpsUrlDspchTrtDtm = 0
          }
        }
        let lastSendObjC = {} // 마지막으로 보낸 변청 Obj
        console.log('fn_SetDetail', pItem)
        if (!_.isEmpty(pSChgLst)) {
          console.log('pSChgLst not empty start reduce')
          let tLst = pSChgLst.filter(a => a.mobpsUrlDspchYn === 'Y')
          tLst = tLst.slice(0, tLst.length)
          lastSendObjC = _.isEmpty(tLst) ? {} : tLst.reduce((a, b) => (_.isEmpty(a.mobpsUrlDspchTrtDtm) ? 0 : Number(a.mobpsUrlDspchTrtDtm)) > (_.isEmpty(b.mobpsUrlDspchTrtDtm) ? 0 : Number(b.mobpsUrlDspchTrtDtm)) ? a : b )
        }
        if (!_.isEmpty(pSChgLst)) {
          console.log('reduce finished well chgObj-->', lastSendObjC)
        }
        pItem.lastSendObjC = lastSendObjC
        if (_.isEmpty(lastSendObjC) || _.isEmpty(lastSendObjC.mobpsUrlDspchTrtDtm)) {
          lastSendObjC.mobpsUrlDspchTrtDtm = 0
        }

        let lastObjC = {} // 마지막으로 생성된 변청 Obj
        if (!_.isEmpty(pSChgLst)) {
          console.log('pSChgLst not empty start reduce')
          let tLst = pSChgLst.slice(0, pSChgLst.length)
          lastObjC = _.isEmpty(tLst) ? {} : tLst.reduce((a, b) => Number(a.erdat + a.erzet) > Number(b.erdat + b.erzet) ? a : b )
        }
        if (!_.isEmpty(pSChgLst)) {
          console.log('reduce finished well lastObjC-->', lastObjC)
        }
        pItem.lastObjC = lastObjC
        let hasC = !_.isEmpty(lastObjC)
        if (!hasC || _.isEmpty(lastObjC.mobpsUrlDspchTrtDtm)) {
          lastObjC.mobpsUrlDspchTrtDtm = 0
        }
        pItem.hasC = hasC
        if (hasC) pItem.urlMoblReciCnsntYn = lastObjC.urlMoblReciCnsntYn // URL모바일수신동의여부

        let lastSendObj = pSSpcObj
        if (hasC) lastSendObj = Number(pSSpcObj.mobpsUrlDspchTrtDtm) >= Number(lastSendObjC.mobpsUrlDspchTrtDtm) ? pSSpcObj : lastSendObjC
        let lastSentDtm = Number(lastSendObj.mobpsUrlDspchTrtDtm)
        pItem.lastSendObj = lastSendObj
        pItem.lastSentDtm = lastSentDtm
        let spcSnoC = '0'
        let spcSnoS = '0'
        if (hasC && Number(lastSentDtm) === Number(lastSendObjC.mobpsUrlDspchTrtDtm)) { spcSnoC = lastSendObjC.spcCndtlElstSno ? lastSendObjC.spcCndtlElstSno : '0' }
        if (hasS && Number(lastSentDtm) === Number(pSSpcObj.mobpsUrlDspchTrtDtm)) { spcSnoS = pSSpcObj.spcCndtlElstSno ? pSSpcObj.spcCndtlElstSno : '0' }
        spcSnoC = this.$bizUtil.lpad(spcSnoC, 5, '0')
        spcSnoS = this.$bizUtil.lpad(spcSnoS, 5, '0')
        pItem.spcSnoC = spcSnoC
        pItem.spcSnoS = spcSnoS
        pItem.docIdSpcChg = pItem.vuchId + pItem.spcSnoC + pItem.spcSnoS
        pItem.bef5Days = Number(this.bef5Days)
        if (hasS || hasC) {
          if ((hasS && Number(this.bef5Days) <= Number(pSSpcObj.erdat)) || (hasC && Number(this.bef5Days) <= Number(pSChgLst[0].erdat))) { // 생성일 기준으로 5일이 안된객체가 있을 때 버튼 활성화
            if (lastSendObj.mobpsUrlDspchYn === 'Y') {
              // 마지막으로 보낸 게 보낸적 있을경우
              if (Number(String(lastObjC.erdat) + String(lastObjC.erzet)) > lastSentDtm) {
                // 마지막으로 생성된 변청의 생성시간이 가장 후에보낸 시간보다 뒤라면
                pItem.contStatCdNm = '작성대상'
                pItem.prgPhseNm = '-'
              } else if (Number(String(pSSpcObj.erdat) + String(pSSpcObj.erzet)) > lastSentDtm) {
                // 마지막으로 생성된 특별조건부의 생성시간이 가장 후에보낸 시간보다 뒤라면
                pItem.contStatCdNm = '작성대상'
                pItem.prgPhseNm = '-'
              } else if (lastSentDtm === Number(lastSendObjC.mobpsUrlDspchTrtDtm)) {
                // 마지막 보낸시간이 마지막 보낸 변청의 시간과같을때 변청 작성중
                pItem.contStatCdNm = '작성중'
                pItem.prgPhseNm = '진행상태'
                if (lastSendObjC.elst4PhseCmpltYn === 'Y' && lastSendObjC.elst2PhseCmpltYn === 'Y' && lastSendObjC.insrdElstYn === 'Y' && lastSendObjC.contrElstYn === 'Y') {
                  pItem.contStatCdNm = '작성완료'
                } else if (lastSendObjC.elst4PhseCmpltYn === 'N' && lastSendObjC.elst2PhseCmpltYn === 'Y') {
                  pItem.contStatCdNm = '작성완료'
                  pItem.prgPhseNm = '재처리'
                }
              } else if (lastSentDtm === Number(pSSpcObj.mobpsUrlDspchTrtDtm)) {
                // 마지막 보낸 특별조건부 시간이 마지막 보낸 시간과 같을때 특별조건부 작성중
                pItem.contStatCdNm = '작성중'
                pItem.prgPhseNm = '진행상태'
                if (pSSpcObj.elst4PhseCmpltYn === 'Y' && pSSpcObj.elst2PhseCmpltYn === 'Y' && pSSpcObj.insrdElstYn === 'Y' && pSSpcObj.contrElstYn === 'Y') {
                  pItem.contStatCdNm = '작성완료'
                } else if (pSSpcObj.elst4PhseCmpltYn === 'N' && pSSpcObj.elst2PhseCmpltYn === 'Y') {
                  pItem.contStatCdNm = '작성완료'
                  pItem.prgPhseNm = '재처리'
                }
              }
            } else if (lastSendObj.mobpsUrlDspchYn === 'N') {
              // 보낸적이 없을 경우.
              pItem.contStatCdNm = '작성대상'
              pItem.prgPhseNm = '-'
            }
          }
        }

        // 특별조건부 없이 변경청약만 있을 경우, 리스트 표시 데이터 교체
        if (!hasS) {
          pItem.vuchId = lastObjC.vuchId
          pItem.spcCndtlElstSno = lastObjC.spcCndtlElstSno
          pItem.mnContrNm = lastObjC.mnContrNm
          pItem.mobslChnlCustId = lastObjC.mobslChnlCustId
          pItem.insrdChnlCustId = lastObjC.insrdChnlCustId
          pItem.mnPrdtNm = lastObjC.mnPrdtNm
          pItem.contPrm = lastObjC.contPrm
          pItem.isuMthScCd = lastObjC.isuMthScCd
          pItem.dfraWcnstSlctYn = lastObjC.dfraWcnstSlctYn
          pItem.contrJobpNm = lastObjC.contrJobpNm
          pItem.insrdJobpNm = lastObjC.insrdJobpNm
          pItem.elstPrgStatCd = lastObjC.elstPrgStatCd
          pItem.doctId = pItem.vuchId
          pItem.elst2PhseCmpltYn = lastObjC.elst2PhseCmpltYn
          pItem.elst4PhseCmpltYn = lastObjC.elst4PhseCmpltYn
          pItem.fullFilePath = ''
          pItem.mobpsUrlDspchYn = lastObjC.mobpsUrlDspchYn
          pItem.mobpsUrlDspchTrtDtm = lastObjC.mobpsUrlDspchTrtDtm
          pItem.mobslElstScCd = lastObjC.mobslElstScCd
          pItem.elstPlanId = lastObjC.elstPlanId
          pItem.contrEltrnMalReciYn = lastObjC.contrEltrnMalReciYn // 계약자전자메일수신여부
          pItem.urlMoblReciCnsntYn = lastObjC.urlMoblReciCnsntYn // URL모바일수신동의여부
        }

        // [선심사PJT] 병력미결 추가
        let pSAnmsObj = pItem.pSAnmsObj
        let hasY = false
        if (!_.isEmpty(pSAnmsObj.crncyCd)) { // 객체 비어있는지 확인.
          hasY = true
        }
        // 모바일청약 URL 발송여부와 상관 없이, 한 영수증ID당 요청일 기준으로 가장 최근 데이터를 리스트에 표시
        if (hasY) {
          let t_spcCndtlElst = pItem.t_spcCndtlElst
          let lastAnmsDtm = Number(String(pSAnmsObj.erdat) + String(pSAnmsObj.erzet))
          let lastSpcDtm = Number(String(t_spcCndtlElst.erdat) + String(t_spcCndtlElst.erzet))  // 특별조건부 마지막 발송시각
          let lastChgDtm = Number(String(lastObjC.erdat) + String(lastObjC.erzet))  // 변경청약 마지막 발송시각

          if ((!lastSpcDtm || lastAnmsDtm > lastSpcDtm) && (!lastChgDtm || lastAnmsDtm > lastChgDtm)) {
            // 특별조건부/변청 데이터가 없거나, 병력미결 데이터가 더 나중에 발생되었을 경우, 해당 영수증ID에 대해 리스트 표시 데이터 교체
            pItem.vuchId = pSAnmsObj.vuchId
            pItem.spcCndtlElstSno = pSAnmsObj.spcCndtlElstSno
            pItem.mnContrNm = pSAnmsObj.mnContrNm
            pItem.mobslChnlCustId = pSAnmsObj.mobslChnlCustId
            pItem.insrdChnlCustId = pSAnmsObj.insrdChnlCustId
            pItem.mnPrdtNm = pSAnmsObj.mnPrdtNm
            pItem.contPrm = pSAnmsObj.contPrm
            pItem.isuMthScCd = pSAnmsObj.isuMthScCd
            pItem.dfraWcnstSlctYn = pSAnmsObj.dfraWcnstSlctYn
            pItem.contrJobpNm = pSAnmsObj.contrJobpNm
            pItem.insrdJobpNm = pSAnmsObj.insrdJobpNm
            pItem.elstPrgStatCd = pSAnmsObj.elstPrgStatCd
            pItem.doctId = pItem.vuchId
            pItem.elst2PhseCmpltYn = pSAnmsObj.elst2PhseCmpltYn
            pItem.elst4PhseCmpltYn = pSAnmsObj.elst4PhseCmpltYn
            pItem.fullFilePath = ''
            pItem.mobpsUrlDspchYn = pSAnmsObj.mobpsUrlDspchYn
            pItem.mobpsUrlDspchTrtDtm = pSAnmsObj.mobpsUrlDspchTrtDtm
            pItem.mobslElstScCd = pSAnmsObj.mobslElstScCd
            pItem.elstPlanId = pSAnmsObj.elstPlanId
            pItem.contStatCdNm = '-'
            pItem.prgPhseNm = '-'
            pItem.contrEltrnMalReciYn = pSAnmsObj.contrEltrnMalReciYn // 계약자전자메일수신여부
            pItem.urlMoblReciCnsntYn = pSAnmsObj.urlMoblReciCnsntYn // URL모바일수신동의여부
            pItem.contrInsrdSameYn = pSAnmsObj.contrInsrdSameYn
            pItem.docIdAnms = pItem.vuchId + this.$bizUtil.lpad(pItem.spcCndtlElstSno, 5, '0')

            // 모청 화면에서 병력고지 없이 서류등록만 진행하는 경우(부본 지점인쇄/알림톡발송 불가)
            if (pSAnmsObj.contrEltrnMalReciYn && pSAnmsObj.urlMoblReciCnsntYn) {
              pItem.isOnlyApnd = pSAnmsObj.contrEltrnMalReciYn.trim() === '' && pSAnmsObj.urlMoblReciCnsntYn.trim() === ''
            }

            // 생성일 기준으로 5일이 안된객체가 있을 때 버튼 활성화
            if ((Number(this.bef5Days) <= Number(pSAnmsObj.erdat))) {
              // 모바일청약 발송기록이 있을 경우
              if (pSAnmsObj.mobpsUrlDspchYn === 'Y') {
                lastSentDtm = Number(pSAnmsObj.mobpsUrlDspchTrtDtm)
                // 요청시간이 마지막 발송시간보다 최근일 경우
                if (lastAnmsDtm > lastSentDtm) {
                  pItem.contStatCdNm = '작성대상'
                  pItem.prgPhseNm = '-'
                } else {
                  pItem.contStatCdNm = '작성중'
                  pItem.prgPhseNm = '진행상태'
                  if (pSAnmsObj.elst4PhseCmpltYn === 'Y' && pSAnmsObj.elst2PhseCmpltYn === 'Y' && pSAnmsObj.insrdElstYn === 'Y' && pSAnmsObj.contrElstYn === 'Y') {
                    pItem.contStatCdNm = '작성완료'
                  } else if (pSAnmsObj.elst4PhseCmpltYn === 'N' && pSAnmsObj.elst2PhseCmpltYn === 'Y') {
                    pItem.contStatCdNm = '작성완료'
                    pItem.prgPhseNm = '재처리'
                  }
                }
              // 발송기록이 없을 경우
              } else if (pSAnmsObj.mobpsUrlDspchYn === 'N') {
                pItem.contStatCdNm = '작성대상'
                pItem.prgPhseNm = '-'
              }
            }
          }
        }
      } else {
        pItem.contStatCdNm = '-'
        pItem.prgPhseNm = '-'
      }
    },

    /******************************************************************************
     * Function명 : fn_FltSearch
     * 설명 : 필터검색 - 팝업에서 넘어오는 함수
     ******************************************************************************/
    fn_FltSearch (pdata) {
      let tempArray = []
      this.orgItems.forEach(item => {
        if (pdata.searchScVal === PSConstants.PLAN_OFR_SEARCH_OPT_CUST_NM.key) { // 고객명
          if ( pdata.chnlCustId === item.contrChnlCustId || pdata.chnlCustId === item.insrdChnlCustId) {
            tempArray.push(item)
          }
        }

        if (pdata.searchScVal === PSConstants.PLAN_OFR_SEARCH_OPT_PRDT_NM.key) { // 상품명
          if (item.prdtNm.indexOf(pdata.searchKeyword.trim()) > -1) {
            tempArray.push(item)
          }
        }

        if (pdata.searchScVal === PSConstants.PLAN_OFR_SEARCH_OPT_VUCH_NUM.key) { // 영수증번호
          if (item.planId.indexOf(pdata.searchKeyword.trim()) > -1) {
            tempArray.push(item)
          }
        }
      })
      this.rsltItemList = []
      this.rsltItemList = tempArray

      this.rsltCnt = (this.rsltItemList)? this.rsltItemList.length : 0
      this.isNodata = (this.rsltCnt <= 0)? true : false
    },

    /************************************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 에러 이벤트
     ************************************************************************************************/
    fn_ServiceFaultHandler(event, serviceName) {
      switch (serviceName) {
        case 'S':
          window.vue.getStore('progress').dispatch('PART')
          this.fn_TableStat()
          if (event && event.msgCd !== 'EFWS034') {
            this.getStore('confirm').dispatch('ADD', event.msgDesc)
          } else {
            // 다른 시스템 호출 간 오류가 발생하였습니다.
            this.getStore('confirm').dispatch('ADD', '데이터 없는 경우이니<br />확인후 다시 시도하세요')
          } // end if

          this.rsltCnt = (this.rsltItemList)? this.rsltItemList.length : 0 
          break
        case 'S5':
          break 
        default:
          this.getStore('confirm').dispatch('ADD', event.msgDesc)
          break
      } // end switch
    },

    /************************************************************************************************
     * Function명  : fn_TableStat
     * 설명        : 데이터 없는 페이지 설정
     * 
     ************************************************************************************************/
    fn_TableStat() {
      this.isNodata = true
      //this.rsltItemList = []
      //Object.assign(this._vm.stndKeyList[0], {stndKeyId: 'next_key', stndKeyVal: '0'} )
      this.rsltItemList.splice(0, this.rsltItemList.length) //바인딩 이벤트 강제 발생 splice 로 삭제 처리
      this.slctItamData = null
    },

    /******************************************************************************
     * Function명 : fn_PopupCustNmUiCard
     * 설명 : 고객상세 팝업 연동
     ******************************************************************************/
    fn_PopupCustNmUiCard (chnlCustId) {
      let nmUiObject = {
        chnlCustId : chnlCustId, //고객채널 ID
        cnsltNo : PSServiceManager.getUserInfo('userId'),  // FC 사번
        parentId   : this.$options.screenId //화면ID]
      }
      
      this.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject, (modalId)=>{
        //Close 콜백 처리
        return
      })

    },

    /************************************************************************************************
     * Function명  : fn_PrgPhseOpenPopup
     * 설명        : 재처리 팝업 오픈
     ************************************************************************************************/
    fn_PrgPhseOpenPopup (pData) {
      console.log('##### MSPPS260M > fn_PrgPhseOpenPopup >>> pData ===>\n', pData)
      
      let popId = MSPPS261P
      if (pData.mobslElstScCd === 'S' || pData.mobslElstScCd === 'C') {
        popId = MSPPS261P
      } else if (pData.mobslElstScCd === 'Y') {
        popId = MSPPS266P
      }
      
      const popPrgPhse = this.$bottomModal.open(popId, {
        properties: {
          rowPopupData: pData
        },
        
        listeners: {
          // 대상팝업 onPopupSearch $emit 이벤트명 
          closePopup: (rtnData) => {
            console.log(rtnData)
            // if (rtnData[0].completion && rtnData[1].completion &&
            //     rtnData[2].completion && rtnData[3].completion &&
            //     this.slctItamData['elst4PhseCmpltYn'] !== 'Y') {
            //   this.slctItamData['elst2PhseCmpltYn'] = 'Y' // 전자서명완료2단계완료여부
            //   this.slctItamData['elst4PhseCmpltYn'] = 'Y'// 전자서명완료4단계완료여부
            //   this.slctItamData['prgPhseNm'] = '-'
            // } else if (rtnData[0].completion && rtnData[1].completion &&
            //     this.slctItamData['elst2PhseCmpltYn'] !== 'Y') {
            //   this.slctItamData['elst2PhseCmpltYn'] = 'Y' // 전자서명완료2단계완료여부
            //   this.slctItamData['elst4PhseCmpltYn'] = 'N'// 전자서명완료4단계완료여부
            // }

            //console.log('##### MSPPS260M > fn_PrgPhseOpenPopup > closePopup >>> this.rowPopupData ===>\n', this.slctItamData)

            this.$bottomModal.close(popPrgPhse) // 모달 닫기                        

            //this.fn_SearchEvent('S', true)
            this.fn_Reset()
            this.fn_ServiceData('S')
          }          
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_WrtObjOpenPopup
     * 설명 : 모바일 청약 미결보완 팝업실행 - 미결보완 작성대상 (구. fn_TSSPS331P_ON)
     ******************************************************************************/
    fn_WrtObjOpenPopup (pData) {
      let popId = MSPPS262P
      if (pData.mobslElstScCd === 'S' || pData.mobslElstScCd === 'C') {
        popId = MSPPS262P
      } else if (pData.mobslElstScCd === 'Y') {
        popId = MSPPS264P
      }

      const popWrtObj = this.$bottomModal.open(popId, {
        properties: {
          pItem: pData
        },
        
        listeners: {
          // 대상팝업 closePopup $emit 이벤트명 
          closePopup: (rtnData) => {
            console.log(rtnData)

            //this.fn_SearchEvent('S', true)
            this.fn_Reset()
            this.fn_ServiceData('S')

            this.$bottomModal.close(popWrtObj) // 모달 닫기                        

          }          
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_PrgStatOpenPopup
     * 설명 : 모바일 청약 미결보완 팝업실행 - 미결보완 고객 진행상태
     ******************************************************************************/
    fn_PrgStatOpenPopup (pData) {
      let popId = MSPPS263P
      if (pData.mobslElstScCd === 'S' || pData.mobslElstScCd === 'C') {
        popId = MSPPS263P
      } else if (pData.mobslElstScCd === 'Y') {
        popId = MSPPS265P
      }

      const popPrgStat = this.$bottomModal.open(popId, {
        properties: {
          pItem: pData
        },
        
        listeners: {
          // 대상팝업 closePopup $emit 이벤트명 
          closePopup: (rtnData) => {
            console.log(rtnData)

            this.$bottomModal.close(popPrgStat) // 모달 닫기

            //this.fn_SearchEvent('S', true)
            this.fn_Reset()
            this.fn_ServiceData('S')
          }          
        }
      })
    },

    /************************************************************************************************
     * Function명  : fn_PrcsCntptKaKaoIapSend
     * 설명        : 미결보완 작성완료 서류 알림톡 발송
     ************************************************************************************************/
    fn_PrcsCntptKaKaoIapSend (slctItem) {
      this.slctItamData = slctItem
      let lv_Vm = this
      let trnstId = 'txTSSPS90P5'
      // 전자문서ID: 부본조회 시 vuchId 필드로 사용
      let eltrnDoctId = this.slctItamData.mobslElstScCd === 'Y'? this.slctItamData.docIdAnms: this.slctItamData.docIdSpcChg
      let pParams = {
        vuchId : eltrnDoctId,
        mobslElstScCd : this.slctItamData.mobslElstScCd, 
        spcCndtlElstSno : this.slctItamData.spcCndtlElstSno
      }

      lv_Vm.post(lv_Vm, pParams, trnstId, 'S').then(function (response) {
        if (response.body) {
          if (response.body.sendRsltCd === 'E000') {  // 발송완료
            lv_Vm.getStore('confirm').dispatch('ADD', '고객님에게 전송이 완료되었습니다.')
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']<br />' + response.msgComm.msgDesc)
          }
        } else {
          if (response && response.msgComm && response.msgComm.msgCd) {
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']<br />' + response.msgComm.msgDesc)
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '알림톡 발송에 실패하였습니다.')
          }
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },

    /************************************************************************************************
     * Function명  : fn_SpcCndtlPrntOpenPopup
     * 설명        : 미결보완 작성완료 서류를 인쇄 Confirm 오픈
     ************************************************************************************************/
    fn_SpcCndtlPrntOpenPopup (slctItem) {
      this.slctItamData = slctItem

      console.log('##### MSPPS260M > fn_SpcCndtlPrntOpenPopup >>> this.slctItamData ===>\n', this.slctItamData)
      
      const lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: false,
          title: "알림",
          //content: this.lv_MsgInsConfirm,
          content : '미결보완 작성완료 서류를 인쇄합니다',
          title_pos_btn: "확인",
          title_neg_btn: "취소"
        },
        listeners: {
          onPopupConfirm: () => {
            this.fn_ServiceData('S5')
            this.$bottomModal.close(lv_AlertPop)
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(lv_AlertPop)
          }
        }
      })
    },

    /************************************************************************************************
     * Function명  : fn_CalcBef5Days
     * 설명        : 5일전 계산
     ************************************************************************************************/
    fn_CalcBef5Days (pYmd, cnt = 0) {
      console.log('fn_CalcBef5Days ' + pYmd + ' ' + cnt)
      let curYmd = PSDateUtil.fn_CurrentDate()
      // 2022.05.24 ASR220500579_(모바일미결보완) 모바일미결 발송 로직 수정
      if (pYmd) {
        curYmd = pYmd
      }
      curYmd = new Date(curYmd.substr(0, 4), curYmd.substr(4, 2) - 1, curYmd.substr(6, 2))
      console.log('fn_CalcBef5Days curYmd' + curYmd)
      if (pYmd) {
        pYmd = new Date(pYmd.substr(0, 4), pYmd.substr(4, 2) - 1, pYmd.substr(6, 2))
        pYmd = pYmd.getDate()
      } else {
        pYmd = curYmd.getDate()
      }
      curYmd.setDate(pYmd - 10)
      console.log('fn_CalcBef5Days curYmd1' + curYmd)
      curYmd = curYmd.getFullYear() + ('0' + (1 + curYmd.getMonth())).slice(-2) + ('0' + curYmd.getDate()).slice(-2)
      console.log('fn_CalcBef5Days curYmd2' + curYmd)
      var lv_Vm = this
      let commHeader = {}
      let pParams = {}
      let btnId = 'S'
      commHeader.requestSystemCode = 'F1339' // 요청 시스템 코드
      commHeader.targetSystemCode = 'sli.ic'
      commHeader.eaiId = ''
      commHeader.reqSrvcNm = 'ICCommMknoCtlImpl'
      commHeader.reqMthdNm = 'selListYmdInfo'
      commHeader.reqVONm = 'sli.ch.vo.ic.comm.ICYmdMngListVO'
      commHeader.resVONm = 'sli.ch.vo.ic.comm.ICYmdMngListVO'
      pParams = {slrcYmYmd: curYmd}
      this.post(lv_Vm, pParams, 'txTSSBC16P1', btnId, commHeader)
        .then(function (response) {
          if (response && response.body) {
            let icymdMngVO = response.body.icymdMngVO
            let counted = cnt // 뒤에서부터 영업일 세기
            let minus5BusinessDay = 0
            for (let index = icymdMngVO.length - 1; index >= 0; index--) {
              if (icymdMngVO[index].holyCd === '00') {
                counted++
              }
              // 5영업일일때 중지
              if (counted === 5) {
                minus5BusinessDay = icymdMngVO[index].slrcYmYmd
                lv_Vm.bef5Days = minus5BusinessDay

                lv_Vm.fn_Reset()
                lv_Vm.fn_ServiceData('S')
                break
              }
            }
            if (counted !== 5) {
              lv_Vm.fn_CalcBef5Days(icymdMngVO[0].slrcYmYmd, counted)
            }
            console.log(minus5BusinessDay)
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
    },

    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>