/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS262P
 * 화면 설명: 미결보완 작성대상
 */
 <template>
  <ur-page-container class="msp" :title="'미결보완 ' + pItem.contStatCdNm" :show-title="true" type="subpage" @on-header-left-click="fn_HeaderBackBtnHandler">
    <!-- <ur-box-container direction="column" alignV="start" class="msp-ps-wrap"> -->
      
      <!--정보기억 show-->
      <MSPPS261D v-show="phase === 1" @closePop="fn_Close" @nextBtnClicked="fn_Next" :pItem="pItem"></MSPPS261D>
      <!--정보리셋 if-->
      <MSPPS262D v-if="phase === 2" @closePop="fn_Close" @prevBtnClicked="fn_Prev" :pNextObj = nextObj  :pItem="pItem"></MSPPS262D>
      <!-- slot 끝 -->
      
      <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
      <div class="ns-height150"></div>

    <!-- </ur-box-container> -->

  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import MSPPS261D from '@/ui/ps/MSPPS261D'
import MSPPS262D from '@/ui/ps/MSPPS262D'

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS262P', 
  screenId: 'MSPPS262P', 
  components: {
    MSPPS261D,
    MSPPS262D
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    pItem: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  //팝업 형태 속성
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      parntSrnId: null, // 부모 화면 ID
      showPopup: true,
      nextObj: {},
      phase: 1 // 1-> 미결보완/고객진행상태 탭 // 2-> 진행상태조회
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {},//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    
  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : 헤더 이전버튼 Handler
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      if (this.phase === 1) { //미결보완 상품정보 및 신청서 확인
        this.$emit('closePopup')
      }
      if (this.phase === 2) { //미결보완 계약 관계자 정보 확인
        this.phase = 1
      }
    },// fn_HeaderBackBtnHandler

    /******************************************************************************
    * Function명  : fn_Close
    * 설명        : 닫기버튼 Handler
    ******************************************************************************/
    fn_Close (o) {
      console.log('close' + o)
      this.$emit('closePopup')
    },

    /******************************************************************************
    * Function명  : fn_Next
    * 설명        : 다음버튼 Handler
    ******************************************************************************/
    fn_Next (o) {
      if (o) {
        this.nextObj = o
        this.phase = 2
      }
    },
    fn_Prev (o) {
      this.phase = 1
    },
    onSizeChange (size) {},


    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>