/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS268D
 * 화면 설명: 병력미결 계약 관계자 정보 확인
 */
<template>
  <!-- Content영역 -->
  <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">
    <ur-box-container alignV="start" componentid="" direction="column" class="pb50">
      <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="pal1624">
        <span class="fs22rem fwb">계약관계자 정보확인</span>
        <div><span class="fs20rem crTy-blue2 fwb mr2">2</span>/<span class="ml2">2</span></div>
      </ur-box-container>
      <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="bd-T-Ty1 h10px bgcolor-3"></ur-box-container>

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list list-pa2024">
        <!-- 피보험자 -->
        <template v-if="contrInsrdSameYn !== 'Y'">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__title fexTy5 pb0">
                <span class="mr10 fwn fs14rem crTy-bk7">피보험자</span>
                <span class="name txtSkip">{{lv_insured.custNm}}</span>
              </div>
              <div class="list-item__contents__info pt0">
                <span class="crTy-bk2">{{ lv_insured.age }}세</span><em>|</em><span class="crTy-bk2">{{lv_insured.custRrnId1}} - {{lv_insured.custRrnId2}}</span><em>|</em><span class="crTy-bk2">{{lv_insured.custCelno}}</span>
              </div>
              <div class="list-item__contents__info pt17">
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
                  <mo-segment-wrapper solid primary v-model="urlTrnsTypInsured[0].key" class="chip-type-wrap">
                    <mo-segment-button class="h30px fs14rem pal0" v-for="(item, idx) in urlTrnsTypList" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
                  </mo-segment-wrapper>
                </ur-box-container>
              </div>
            </div>
          </mo-list-item>
        </template>
         <!-- 피보험자 -->

        <!-- 계약자 -->
        <template>
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__title fexTy5 pb0">
                <span class="mr10 fwn fs14rem crTy-bk7" v-if="contrInsrdSameYn === 'Y'">피보험자 / 계약자</span>
                <span class="mr10 fwn fs14rem crTy-bk7" v-else>계약자</span>
                <span class="name txtSkip">{{lv_contr.custNm}}</span>
              </div>
              <div class="list-item__contents__info pt0">
                <span class="crTy-bk2">{{ lv_contr.age }}세</span><em>|</em><span class="crTy-bk2">{{lv_contr.custRrnId1}} - {{lv_contr.custRrnId2}}</span><em>|</em><span class="crTy-bk2">{{lv_contr.custCelno}}</span>
              </div>
              <div class="list-item__contents__info pt17">
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
                  <mo-segment-wrapper solid primary v-model="urlTrnsTypContr[0].key" class="chip-type-wrap">
                    <mo-segment-button class="h30px fs14rem pal0" v-for="(item, idx) in urlTrnsTypList" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
                  </mo-segment-wrapper>
                </ur-box-container>
              </div>
            </div>
          </mo-list-item>
        </template>
        <!-- 계약자 -->
      </ur-box-container>

    </ur-box-container>

    <template>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click.stop="$emit('closePop')">취소</mo-button>
          <mo-button componentid="mo_button_003" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Send">발송</mo-button>
        </div>
      </ur-box-container>
    </template>
  </ur-box-container>
   <!--/ root ur-page-container -->
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import PSElstUtil from '@/ui/ps/comm/PSElstUtil'

export default {
/***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS268D', 
  screenId: 'MSPPS268D',

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    pItem: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {            
      lv_elstPlanId: '',
      checkbox: {
        '1': false,
        '2': false,
        '9': true
      },
      hoshInfoList: '', // 계약자/피보험자 정보
      planInfoData: '', // 상품정보
      jsonString: '',
      standardAge19: 19, // 기준 나이 19 / 미성년자
      standardAge14: 14, // 기준 나이 14 / 본인서명불가 나이
      contrInsrdSameYn: 'N', // 계피상이여부
                  
      // 계약자
      lv_contr: {
        custNm: '',
        age: '',
        custRrnId: '',
        custRrnId1: '',
        custRrnId2: '',
        custCelno: '',
        chnlCustId: '',
        chnlCstgrId: '',
        showNameTooltip: false // 이름이 4자이상인 경우 toolTip표시
      },
      // 피보험자
      lv_insured: {
        custNm: '',
        age: '',
        custRrnId: '',
        custRrnId1: '',
        custRrnId2: '',
        chnlCustId: '',
        chnlCstgrId: '',
        showNameTooltip: false // 이름이 4자이상인 경우 toolTip표시
      },
      lv_fc: { // fc
        fcNm: '', // FC 이름
        fcCelno: '',
        showNameTooltip: false // 이름이 4자이상인 경우 toolTip표시
      },

      urlTrnsTypList: [ // URL전송유형 목록
        {
          key: 'K',
          label: '카카오톡'
        },
        {
          key: 'T',
          label: '태블릿'
        }
      ],
      urlTrnsTypContr: [{ // 계약자 URL전송유형
        key: 'K',
        label: '카카오톡'
      }],
      urlTrnsTypInsured: [{ // 피보험자 URL전송유형
        key: 'K',
        label: '카카오톡'
      }],
      urlTrnsTypFC: [{ // FC URL전송유형
        key: 'K',
        label: '카카오톡'
      }],
    }
  },//data

 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/
  created () {    
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

    let nObj = this.pItem.pSAnmsObj && this.pItem.pSAnmsObj.vuchId ? this.pItem.pSAnmsObj : {}
    let pId = this.pItem.pSAnmsObj && this.pItem.pSAnmsObj.elstPlanId ? this.pItem.pSAnmsObj.elstPlanId : ''

    if (pId === '') {
      this.fn_GetElstPId(nObj.vuchId)
    } else {
      this.lv_elstPlanId = pId
      this.fn_SetDataFromElstPId(pId)
    }
  },//created

  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
    this.fn_Valid()
  },//mounted

  destroyed () {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
  },//destroyed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_GetElstPId
    * 설명       : 영수증ID를 통해 00002번 테이블의 전자서명설계ID 조회
    ******************************************************************************/
    fn_GetElstPId (vuchId) {
      let lv_Vm = this
      let pParams = {
        vuchId: vuchId
      }
      const trnstId = 'txTSSPS90S3'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (response.body && response.body.elstPlanId) { // FC 이름이 길어질 경우 tooltip표시 여부 확인
            let elstPlanId = response.body.elstPlanId
            lv_Vm.lv_elstPlanId = elstPlanId
            lv_Vm.fn_SetDataFromElstPId(elstPlanId)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_SetDataFromElstPId
    * 설명       : 심사결과 정보 호출
    ******************************************************************************/
    fn_SetDataFromElstPId (pId) {
      this.jsonString = JSON.stringify({tmodel: {searchCriteria: {zaPlanId: pId}}, vuchId: ' ', dpstrInfo: {chnlCustId: ''}})
      PSElstUtil.fn_EltrnSignBaseInfo('txTSSPS13S2', this.jsonString, this.fn_ServiceDataResultHandler)
    },
    
    /******************************************************************************
    * Function명 : fn_ServiceDataResultHandler
    * 설명       : 전자서명 초기 데이타 호출 결과 컨트롤 함수
    ******************************************************************************/
    fn_ServiceDataResultHandler: function (event) {
      if (event.hoshInfoList) {
        this.hoshInfoList = event.hoshInfoList // 계약자/피보험자 정보
        this.planInfoData = event.planInfoData // 상품정보

        // 계약자 정보 설정 hoshInfoList[0]
        this.lv_contr.custNm = this.hoshInfoList[0].insrdNm // 계약자 이름
        this.lv_contr.age = this.hoshInfoList[0].age // 계약자 나이
        this.lv_contr.custRrnId = this.hoshInfoList[0].custRrnId // 계약자 주민번호
        this.lv_contr.custRrnId1 = this.hoshInfoList[0].custRrnId.substring(0, 6) // 계약자 주민번호 앞자리
        this.lv_contr.custRrnId2 = this.hoshInfoList[0].custRrnId.substring(6, 7) + '******' // 계약자 주민번호 뒷 한자리 + 마스킹
        this.lv_contr.custCelno = this.hoshInfoList[0].detail.celno // 계약자 전화번호
        this.lv_contr.chnlCustId = this.hoshInfoList[0].detail.chnlCustId // 계약자 채널고객ID
        this.lv_contr.chnlCstgrId = this.hoshInfoList[0].detail.chnlCstgrId // 계약자 채널그룹ID

        // 피보험자
        if (this.hoshInfoList[0].agtFileId !== this.hoshInfoList[1].agtFileId) { // 계피상이여부
          this.contrInsrdSameYn = 'N' // 계피상이
          // 피보험자 정보 설정 hoshInfoList[1]
          this.lv_insured.custNm = this.hoshInfoList[1].insrdNm // 피보험자 이름
          this.lv_insured.age = this.hoshInfoList[1].age // 피보험자 나이
          this.lv_insured.custRrnId = this.hoshInfoList[1].custRrnId // 피보험자 주민번호
          this.lv_insured.custRrnId1 = this.hoshInfoList[1].custRrnId.substring(0, 6) // 피보험자 주민번호 앞자리
          this.lv_insured.custRrnId2 = this.hoshInfoList[1].custRrnId.substring(6, 7) + '******' // 피보험자 주민번호 뒷 한자리 + 마스킹
          this.lv_insured.custCelno = this.hoshInfoList[1].detail.celno // 피보험자 전화번호
          this.lv_insured.chnlCustId = this.hoshInfoList[1].detail.chnlCustId // 피보험자 채널고객ID
          this.lv_insured.chnlCstgrId = this.hoshInfoList[1].detail.chnlCstgrId // 피보험자 채널그룹ID
        } else { // 계피동일
          this.contrInsrdSameYn = 'Y' // 계피동일
        }

        // 청약url표시 검증
        this.fn_urlTrnsShowCheck()
        // fc정보 조회
        this.fn_SelFcInfo()
      } else { // if (hoshInfoList) { 종료
        console.log('#### fn_ServiceDataResultHandler 오류')
      }
    },
    
    /******************************************************************************
    * Function명 : fn_SelFcInfo
    * 설명       : FC정보조회
    *            : FC전화번호는 getStore('userInfo')에 가지고 있지 않음
    ******************************************************************************/
    fn_SelFcInfo () {
      let lv_Vm = this
      let pParams = {userEno: this.getStore('userInfo').getters.getUserInfo.userId}
      const trnstId = 'txTSSBC20S3'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          let fc_UserVo = response.body
          lv_Vm.lv_fc.fcNm = fc_UserVo.userNm
          // 전화번호는 00011112222 형태이기때문에, -을 추가하기 위해서 util 호출
          lv_Vm.lv_fc.fcCelno = lv_Vm.$bizUtil.telNoWithHyphen(fc_UserVo.celno.trim())
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_urlTrnsShowCheck
    * 설명       : 청약 URL전송여부 표시
    ******************************************************************************/
    fn_urlTrnsShowCheck () {      
      // 계약자 : 14세 이상인 경우 발송
      if (this.$bizUtil.compareCurrentAge(this.lv_contr.custRrnId.substr(0, 6), this.standardAge14) === true) {
        this.checkbox['1'] = true
      }

      // 피보험자 : 계약자 != 피보험자 && 14세 이상인 경우 발송
      if (this.contrInsrdSameYn !== 'Y' &&
          this.$bizUtil.compareCurrentAge(this.lv_insured.custRrnId.substr(0, 6), this.standardAge14) === true) {
        this.checkbox['2'] = true
      }
    },

    /******************************************************************************
     * Function명  : fn_ServiceResultHandler
     * 설명        : 서비스 호출 결과 함수 정의
     ******************************************************************************/
    fn_ServiceResultHandler: function (event, serviceName) {
      let lv_data = event.data
      switch (serviceName) {
        case 'S5': // 서버에 이미 서명완료되었는지 확인
          let eltrnSignCmpltPhseTrtVO = Object.assign({}, lv_data)
          let eltrnSignPhseCd01 = eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd01.trim() // 전자문서보관

          if (!eltrnSignPhseCd01 || eltrnSignPhseCd01 === 'N') {
          } else {
            this.fn_BottomAlert("이미 서명 완료되었습니다")
          }
          break
        default:
          break
      }
    },

    /******************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 에러 이벤트
     ******************************************************************************/
    fn_ServiceFaultHandler: function (event, serviceName) {
      switch (serviceName) {
        case 'S5':
          this.isMobpsValMessge = '고객서명정보 조회시 오류가 발생하였습니다.'
          break
        default:
          break
      }
      this.isMobpsValCheck = true
    },

    /******************************************************************************
    * Function명 : fn_BottomAlert
    * 설명       : 알림 팝업 호출
    ******************************************************************************/
    fn_BottomAlert (contents) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         single: true,
         title: '알림',
         content: contents
       },
       listeners: {
         onPopupConfirm: () => {
           this.$emit('prevBtnClicked')
           this.$bottomModal.close(this.lv_AlertPop);
         },
         onPopupClose: () => {
           this.$emit('prevBtnClicked')
           this.$bottomModal.close(this.lv_AlertPop);
           }
         }
      })
    },
    
    /******************************************************************************
    * Function명  : fn_Valid
    * 설명        : 이미 서명완료되었는지 확인
    ******************************************************************************/
    fn_Valid () {
      let pParams = {}
      pParams.appId = 'sli.fom'
      pParams.trnstId = 'txTSSPS32S1'
      pParams.fnctScCd = 'S'
      pParams.serviceId = ''
      pParams.reqSrvcNm = 'DCAddmAnmsSignCtlImpl'
      pParams.reqMthdNm = 'selEltrnSingCmpltPhseTrt'
      pParams.reqVONm = 'sli.mo.vo.dc.eltrnsign.DCEltrnSignCmpltPhseTrtVO'
      pParams.resVONm = 'sli.mo.vo.dc.eltrnsign.DCEltrnSignCmpltPhseTrtVO'
      pParams.data = {}
      pParams.data.moblBusnScCd = 'NB' // 모바일업무구분코드
      pParams.data.eltrnDoctScCd = '19' // 전자문서구분코드
      pParams.data.eltrnDoctId = this.pItem.docIdAnms
      pParams.srnId = this.$options.screenId // 대표 화면 명
      PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, 'S5', this.fn_ServiceFaultHandler)
    },

    /******************************************************************************
    * Function명  : fn_Send
    * 설명        : 발송버튼 클릭시 호출
    ******************************************************************************/
    fn_Send () {
      var lv_Vm = this
      if (( this.contrInsrdSameYn === 'Y' && !this.checkbox['1']) || (!this.checkbox['1'] && !this.checkbox['2'])) {
        return
      }

      var trnstId = 'txTSSPS90P4'
      let pParam = {
        vuchId: this.pItem.planId,
        elstPlanId: _.isEmpty(this.lv_elstPlanId) ? this.pItem.vuchId : this.lv_elstPlanId
      }
      let pSAddmAnmsListSVO = []

      // contPtcpScCd 계약관계인 구분코드: 1계약자 2주피보험자 9FC
      for (let i in this.checkbox) {
        if (!this.checkbox[i]) continue
        pParam.contPtcpScCd = i
        if (i === '1') {
          pParam.elstPartnNo = this.hoshInfoList[0].partnerId
          pParam.mobslChnlCustId = this.hoshInfoList[0].agtFileId
          pParam.mobslChnlCstgrId = this.hoshInfoList[0].detail.chnlCstgrId // 모바일영업채널고객그룹ID
          pParam.contPtcpTelnoEncr = this.$bizUtil.replaceAll(this.hoshInfoList[0].detail.celno, '-', '') // 계약관계인전화번호암호화
          pParam.contPtcpRrnEncr = this.hoshInfoList[0].custRrnId // 계약관계인주민등록번호암호화
          pParam.mobslChnlCustNm = this.hoshInfoList[0].insrdNm // 모바일영업채널고객명
          pParam.mobpsContrRltnScCd = '01' // 모바일청약계약자관계구분코드 / 01:본인, 02:배우자, 03:부모, 04:자녀
          pParam.mobpsSignMthCd = this.urlTrnsTypContr[0].key // 모바일청약서명방법코드
          pParam.isSendKaKao = this.urlTrnsTypContr[0].key === 'K' ? '1' : '2'
        }
        if (i === '2') {
          pParam.elstPartnNo = this.hoshInfoList[1].partnerId
          pParam.mobslChnlCustId = this.hoshInfoList[1].agtFileId
          pParam.mobslChnlCstgrId = this.hoshInfoList[1].detail.chnlCstgrId // 모바일영업채널고객그룹ID
          pParam.contPtcpTelnoEncr = this.$bizUtil.replaceAll(this.hoshInfoList[1].detail.celno, '-', '') // 계약관계인전화번호암호화
          pParam.contPtcpRrnEncr = this.hoshInfoList[1].custRrnId // 계약관계인주민등록번호암호화
          pParam.mobslChnlCustNm = this.hoshInfoList[1].insrdNm // 모바일영업채널고객명
          pParam.mobpsContrRltnScCd = ' ' // 모바일청약계약자관계구분코드 / 01:본인, 02:배우자, 03:부모, 04:자녀
          pParam.mobpsSignMthCd = this.urlTrnsTypInsured[0].key // 모바일청약서명방법코드
          pParam.isSendKaKao = this.urlTrnsTypInsured[0].key === 'K' ? '1' : '2'
        }
        if (i === '9') {
          pParam = {}
          pParam.vuchId = this.pItem.planId
          pParam.elstPlanId = _.isEmpty(this.lv_elstPlanId) ? this.pItem.vuchId : this.lv_elstPlanId
          pParam.mobpsContrRltnScCd = ' ' // 모바일청약계약자관계구분코드 / 01:본인, 02:배우자, 03:부모, 04:자녀
          pParam.contPtcpScCd = '9' // 계약관계인구분코드 / 9:FC
          pParam.contPtcpTelnoEncr = this.$bizUtil.replaceAll(this.lv_fc.fcCelno, '-', '') // 계약관계인전화번호암호화
          pParam.mobslChnlCustNm = this.lv_fc.fcNm // 모바일영업채널고객명
          pParam.mobpsSignMthCd = this.urlTrnsTypFC[0].key // 모바일청약서명방법코드
          pParam.coprPrtrMuagrYn = 'N' // 공동친권합의여부
          pParam.isSendKaKao = '2'
        }
        pParam.spcCndtlElstSno = lv_Vm.pItem.pSAnmsObj.spcCndtlElstSno
        pParam.mobslElstScCd = 'Y'
        pSAddmAnmsListSVO.push(Object.assign({}, pParam))
      }
      if (pSAddmAnmsListSVO.length === 0) {
        lv_Vm.getStore('confirm').dispatch('ADD', '계약관계자가 선택되지 않았습니다.')
        return
      }
      let pParams = {
        listVO: pSAddmAnmsListSVO,
        contrInsrdSameYn: this.contrInsrdSameYn
      }
      
      lv_Vm.post(lv_Vm, pParams, trnstId, 'S').then(function (response) {
        if ( response.body && response.msgComm) {
          if (response.msgComm.msgCd === 'EFWS003') {
            if (response.body) {
              if (response.body.listVO) {
                for (let i = 0; i < response.body.listVO.length; i++) {
                  if ( response.body.wkRslt === '0') { // 정상발송 0
                  } else { // 비정상케이스
                    lv_Vm.getStore('confirm').dispatch('ADD', '알림톡 발송에 실패하였습니다.')
                    return
                  }
                }
              }
              // 비정상 케이스 없었으므로 화면종료.
              if (lv_Vm.urlTrnsTypContr[0].key === 'K' || lv_Vm.urlTrnsTypInsured[0].key === 'K') lv_Vm.getStore('confirm').dispatch('ADD', '고객님에게 전송이 완료되었습니다.')
              lv_Vm.$emit('closePop')
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']<br />' + response.msgComm.msgDesc)
          }
        } else {
          if (response && response.msgComm && response.msgComm.msgCd) {
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']<br />' + response.msgComm.msgDesc)
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '알림톡 발송에 실패하였습니다.')
          }
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    
    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>